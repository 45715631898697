/* Kapatma butonu */
.close-button-player {
    position: absolute; /* Sabit pozisyon */
    top: 0px; /* Üstten uzaklık */
    right: 0px; /* Sağdan uzaklık */
    width: 30px; /* Buton genişliği */
    height: 30px; /* Buton yüksekliği */
    background-color: transparent; /* Şeffaf arka plan */
    color: #000; /* Siyah yazı rengi */
    border: none; /* Çerçeve kaldırılır */
    font-size: 25px; /* Yazı boyutu */
    font-weight: bold; /* Yazı kalınlığı */
    cursor: pointer; /* İmleç değişimi */
    border-radius: 50%; /* Daire şekli */
    display: flex; /* Flexbox kullanılarak içerik ortalanır */
    justify-content: center; /* Yatayda ortalama */
    align-items: center; /* Dikeyde ortalama */
    transition: all 0.3s ease; /* Geçiş animasyonu */
}

.close-button-player:hover {
    background-color: #f5f5f5; /* Hover durumunda açık gri arka plan */
    color: #008080; /* Hover durumunda yazı rengi */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); /* Hafif bir gölge */
}

.close-button-player:focus {
    outline: none; /* Fokus durumunda çerçeve kaldırılır */
}
.scrollable-select select {
    max-height: 150px;
    overflow-y: auto;
    width: 100%;
}

.player-details {
    display: flex;
    gap: 10px;
    margin-top: 15px;
}
.team-selection {
    margin-bottom: 15px;
  }
  .player-entries {
    max-height: 300px; /* Liste için maksimum yükseklik */
    min-height: 100px;
    overflow-y: auto; /* Dikeyde scroll özelliği */
    border: 1px solid #ccc; /* Listeyi belirginleştirmek için çerçeve */
    border-radius: 5px; /* Kenarları yuvarlama */
    padding: 10px; /* İçerideki elemanlar için boşluk */
    margin-top: 15px; /* Diğer elemanlardan ayırmak için üst boşluk */
    background-color: #f9f9f9; /* Hafif bir arka plan rengi */
}
  
  .player-inputs {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    align-items: center;
  }
  
  .custom-input {
    flex: 1;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .custom-select {
    flex: 0.5;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .add-entry-button {
    margin-top: 10px;
    background-color: #008080;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .add-entry-button:hover {
    background-color: #005959;
  }
  .player-inputs {
    display: flex;
    gap: 10px; /* Aradaki boşluğu ayarlar */
    align-items: center;
    margin-bottom: 15px;
  }
  
  .custom-input {
    flex: 1; /* Eşit genişlikte yerleştir */
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .custom-select {
    flex: 0.5; /* Daha dar bir alan için */
    padding: 10px;
    border-radius: 5px;
    margin-top: 2px;
    border: 1px solid #ccc;
  }

  
  .team-list {
    max-height: 150px; /* Scrollable liste için yükseklik sınırı */
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 5px;
    padding: 5px;
  }
  
  .team-item {
    padding: 8px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
  }
  
  .team-item:last-child {
    border-bottom: none;
  }
  
  .team-item:hover {
    background-color: #f0f0f0;
  }
  
  .team-item.selected {
    background-color: #008080;
    color: #fff;
  }
  .custom-file-input {
    display: inline-block;
    margin-right: 10px; /* Görsel olarak biraz boşluk bırak */
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px; /* Köşeleri yuvarlak yap */
    background-color: #f9f9f9;
    cursor: pointer;
  }
  .custom-file-input:hover {
    background-color: #e6e6e6;
  }
  
  
  .player-inputs {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .custom-input {
    margin-left: 10px;
    flex: 1;
  }
  


  .photo-input-wrapper {
    position: relative;
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  
  .photo-label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 35%;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px; /* Köşeleri yuvarlak yap */
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .photo-input {
    display: none; /* Dosya seçme input'u tamamen gizlenir */
  }
  .photo-input-wrapper {
    display: flex;
    flex-direction: column; /* Fotoğraf seçme butonunu ve dosya adını dikey hizala */
    align-items: center; /* Ortala */
  }
  
  .file-name {
    font-size: 12px;
    margin-bottom: 10px;
    color: #555;
    margin-top: 5px; /* Buton ile dosya adı arasında boşluk bırak */
    font-style: italic;
    text-align: center; /* Ortala */
  }
  