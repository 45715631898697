.update-match-container {
    padding: 20px;
}

.update-match-title {
    font-size: 24px;
    margin-bottom: 20px;
}

.update-match-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.update-match-form-row {
    display: flex;
    align-items: center;
    gap: 10px;
}

.update-match-label {
    width: 150px;
    font-size: 18px;
    background-color: white;
    border-radius: 10px;
    font-family: 'Trebuchet MS', sans-serif;
    font-weight: bold;
}

.update-match-input {
    flex: 1;
    padding: 5px;
    font-size: 16px;
}
.match-info-container {
    text-align: center;
    margin-bottom: 20px;
    padding: 15px;
    background-color: #f7f7f7; /* Hafif gri arka plan */
    border: 1px solid #ddd; /* İnce bir kenarlık */
    border-radius: 8px; /* Yuvarlatılmış köşeler */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Hafif gölge */
}

.match-info-row {
    margin: 10px 0; /* Satırlar arasında boşluk */
}

.team-info {
    font-size: 18px; /* Yazı boyutu */
    font-weight: bold; /* Kalın yazı */
    color: #333; /* Yazı rengi */
    text-transform: capitalize; /* İlk harfi büyük yap */
}


.update-match-button {
    padding: 5px 10px;
    background-color: #008080;
    color: white;
    font-family: 'Trebuchet MS', sans-serif;
    font-size: 18px;
    margin-bottom: 10px;
    border: none;
    cursor: pointer;
    border-radius: 10px;
}

.update-match-button:hover {
    background-color: #006666;
}

.update-match-image {
    margin-top: 10px;
    width: 100px;
    height: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.delete-row {
    margin-top: 20px;
}

.delete-button {
    background-color: red;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.delete-button:hover {
    background-color: darkred;
}
