/* Genel düzen */
.app-container {
    display: flex;
    min-height: 100vh;
  }
  
  
  .content {
   
    width: 100%;
    background-color: #f1f1f1; /* Hafif mor arka plan */
  }
  
.editor-menubar {
    display: flex;
    height: 100vh;
    font-family: 'Trebuchet MS', sans-serif;
}

/* Sol Menü Barı */


.logo-container {
    width: 250px;
    margin-bottom: 30px;
}

.logo {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.menu-item {
    padding: 10px;
    text-align: center;
    color: white;
    text-decoration: none;
    margin-bottom: 10px;
    transition: background-color 0.3s;
    font-family: 'Trebuchet MS', sans-serif;
    font-weight: bold;
    font-size: 20px;
}

.menu-item:hover {
    background-color: #4a005f;
}

.menu-item.active {
    background-color: #f5f0ff;
    color: #4a005f;
    border-radius: 5px;
    font-weight: bold;
    font-size: 20px;
}


p {
    font-size: 18px;
    color: #f5f0ff;
    font-family: 'Trebuchet MS', sans-serif;
}

.editor-content {
    display: flex;
    flex-direction: column; /* Dikey düzenleme */
    height: 90vh; /* Tüm ekranı kaplasın */
    margin-left: -20px; /* Menü çubuğuna yer bırak */
    padding: 20px;
    gap: 20px; /* Stat bar ve main area arasında boşluk */
}

.editor-stats-bar {
    display: flex;
    justify-content:space-around;
    align-items: center;
    background-color: #f1f1f1;;
    color: white;
    height: 40px;
    width: calc(100% - 250px); /* Menü çubuğunun genişliği çıkarılıyor */
    margin-left: 250px; /* Menü çubuğuna hizalama */
    margin-top: 15px; /* Menü çubuğuna hizalama */
    padding: 25px;
    border-radius: 0 0 10px 10px;
    position: fixed;
    z-index: 999
}

.editor-stat-item {
    text-align: center;
    padding: 20px;
    background-color: #320037;
    border-radius: 20px;
    font-size: 16px;
    font-weight: bold;
    width: 50px;
    height: 60px; /* Her kutu için genişlik */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #f5f0ff;
}

.editor-stat-item span {
    display: block;
    font-size: 20px; /* Başlık boyutu */
    margin-bottom: 5px;
}

.editor-stat-item .stat-value {
    font-size: 35px; /* Sayı boyutu */
    font-weight: bold;
}

/* Ana İçerik Alanı */
.main-area {
    flex: 1;
    background-color: #320037;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #40a139;
    padding: 20px;
}

/* Mobil görünüm */
@media (max-width: 768px) {
    .editor-stats-bar {
        flex-direction: column; /* Dikey dizilim */
        padding: 10px;
        gap: 10px;
    }

    .editor-stat-item {
        width: 100%; /* Genişlik tam olsun */
        max-width: 300px;
    }
}