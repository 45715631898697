/* Modal arkaplan */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal içeriği */
.modal-content {
    background: white;
    padding: 30px;
    border-radius: 15px;
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
}

/* Modal başlık */
.modal-title-addTeam {
    background-color: #008080;
    color: #ffffff;
    font-size: 24px; /* Yazı boyutunu koruyoruz */
    text-align: center;
    margin-bottom: 15px; /* Alt boşluk */
    font-family: 'Trebuchet MS', sans-serif;
    font-weight: bold;
    padding: 20px; /* Daha fazla iç boşluk eklenir */
    border-radius: 10px; /* Kenarların daha oval olması için artırıldı */
    width: 90%; /* Genişliği artırılır */
    margin: 0 auto 20px; /* Ortalar ve alt boşluk ekler */
}
select {
    background-color: white; /* Arka planı beyaz yapar */
    color: black; /* Yazı rengini siyah yapar */
    font-family: 'Trebuchet MS', sans-serif; /* Yazı tipini Trebuchet MS yapar */
    font-size: 18px; /* Yazı boyutunu artırır */
    font-weight: bold;
    padding: 8px; /* İç boşluk ekler */
    border: 1px solid #ccc; /* Çerçeve rengi */
    border-radius: 5px; /* Köşeleri yuvarlar */
    width: 100%; /* Genişliği %100 yapar */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Hafif gölge ekler */
    cursor: pointer; /* Üzerine gelindiğinde imleci değiştirir */
}

select:focus {
    outline: none; /* Odaklandığında mavi çerçeveyi kaldırır */
    border-color: #008080; /* Odaklandığında çerçeve rengini değiştirir */
    box-shadow: 0 0 5px rgba(0, 128, 128, 0.5); /* Odaklandığında hafif bir parıltı ekler */
}

/* Ortak input stilleri */
input[type="text"], input[type="file"],input[type="date"],input[type="number"] {
    background-color: white; /* Arka plan beyaz */
    color: black; /* Yazı rengi siyah */
    font-family: 'Trebuchet MS', sans-serif; /* Yazı tipi */
    font-size: 18px; /* Yazı boyutu */
    font-weight: bold;

    padding: 10px; /* İç boşluk */
    border: 1px solid #ccc; /* Çerçeve rengi */
    border-radius: 5px; /* Köşeleri yuvarlatır */
    width: 95%; /* Genişlik tam olur */
    margin-bottom: 15px; /* Alt boşluk ekler */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Hafif gölge */
    transition: all 0.3s ease-in-out; /* Etkileşimde geçiş animasyonu */
}

input[type="text"]:focus, input[type="file"]:focus,input[type="date"]:focus,input[type="number"]:focus {
    outline: none; /* Varsayılan mavi çerçeveyi kaldırır */
    border-color: #008080; /* Odaklanınca çerçeve rengi */
    box-shadow: 0 0 5px rgba(0, 128, 128, 0.5); /* Hafif bir parıltı */
}

/* Logo Yükle butonu için label tasarımı */
.logo-label {
    display: block; /* Blok eleman yapar */
    font-family: 'Trebuchet MS', sans-serif; /* Yazı tipi */
    font-size: 18px; /* Yazı boyutu */
    color: #008080; /* Yazı rengi */
    margin-bottom: 10px; /* Alt boşluk */
    font-weight: bold; /* Kalın yazı */
}


/* Kapatma butonu */
.close-button {
    position: absolute; /* Sabit pozisyon */
    top: -15px; /* Üstten uzaklık */
    right: 0px; /* Sağdan uzaklık */
    width: 30px; /* Buton genişliği */
    height: 30px; /* Buton yüksekliği */
    background-color: transparent; /* Şeffaf arka plan */
    color: #000; /* Siyah yazı rengi */
    border: none; /* Çerçeve kaldırılır */
    font-size: 25px; /* Yazı boyutu */
    font-weight: bold; /* Yazı kalınlığı */
    cursor: pointer; /* İmleç değişimi */
    border-radius: 50%; /* Daire şekli */
    display: flex; /* Flexbox kullanılarak içerik ortalanır */
    justify-content: center; /* Yatayda ortalama */
    align-items: center; /* Dikeyde ortalama */
    transition: all 0.3s ease; /* Geçiş animasyonu */
}

.close-button:hover {
    background-color: #f5f5f5; /* Hover durumunda açık gri arka plan */
    color: #008080; /* Hover durumunda yazı rengi */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); /* Hafif bir gölge */
}

.close-button:focus {
    outline: none; /* Fokus durumunda çerçeve kaldırılır */
}




/* Buton stili */
.create-button {
    background-color: #008080;
    color: white;
    padding: 12px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-family: 'Trebuchet MS', sans-serif;
    font-weight: bold;
    font-size: 24px;

    transition: background-color 0.3s ease;
}

.create-button:hover {
    background-color: #006666;
}

/* Hata ve başarı mesajları */
.error-message {
    color: red;
    font-size: 20px;
    font-family: 'Trebuchet MS', sans-serif;
    font-weight: bold;
    text-align: center;
}

.logo-label {
    font-weight: bold;
    color: #008080;
    font-family: 'Trebuchet MS', sans-serif;
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
}
.success-message {
    color: green;
    font-family: 'Trebuchet MS', sans-serif;
    font-weight: bold;
    font-size: 25px;
    text-align: center;
}
.custom-input {
    width: 250px; /* Genişlik */
    padding: 10px; /* İçerik boşluğu */
    margin-top: 10px; /* Üst boşluk */
    border: 1px solid #ccc; /* Kenarlık */
    border-radius: 5px; /* Kenar ovalleştirme */
    font-size: 14px; /* Yazı boyutu */
    color: #000; /* Yazı rengi siyah */
    background-color: #fff; /* Arka plan beyaz */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Hafif gölge */
}

.custom-input::placeholder {
    color: #000; /* Placeholder yazı rengini siyah yapar */
}

/* Logo Yükleme Alanı */
.custom-file-label {
    width: 380px; /* Genişlik */
    padding: 10px; /* İçerik boşluğu */
    border: 1px solid #ccc; /* Kenarlık */
    border-radius: 5px; /* Kenar ovalleştirme */
    font-size: 17px; /* Yazı boyutu */
    color: #000; /* Yazı rengi siyah */
    background-color: #fff; /* Arka plan beyaz */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Hafif gölge */
    font-family: 'Trebuchet MS', sans-serif;
    font-weight: bold;
    
}

.custom-file-input {
    display: none; /* Dosya seç alanını gizler */
}
