.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 15px;
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.custom-input {
    width:100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.team-list,
.player-dropdown {
    max-height: 100px; /* Scrollable liste için yükseklik sınırı */
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 0px;
    padding: 5px;
}

.team-item,
.player-item {
    padding: 5px;
    cursor: pointer;
}

.team-item:hover,
.player-item:hover {
    background: #f0f0f0;
}

.team-item.selected {
    background: #ffffff;
    color: rgb(0, 0, 0);
}

.player-input-container {
    margin-bottom: 5px;
}

.player-row {
    position: relative;
    margin-bottom: 10px;
}

.add-row-button,
.save-button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background: #008080;
    color: #fff;
    font-family: 'Trebuchet MS', sans-serif;
    font-weight: bold;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.add-row-button:hover,
.save-button:hover {
    background: #008080;
}

.error-message {
    color: red;
    font-size: 0.9rem;
    margin-top: 10px;
}

.success-message {
    color: green;
    font-size: 0.9rem;
    margin-top: 10px;
}
/* Add_Team_Player.css */

.scrollable-container {
    max-height: 300px; /* Maksimum yüksekliği belirleyin */
    overflow-x: auto; /* Yatay kaydırma etkinleştir */
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
}

.player-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}
