/* Ana container */
.seasons-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; /* İkiye böl */
    gap:150px;
    padding: 50px;
}

.existing-seasons{
    padding: 5px;
    width:600px;
    
}


.delete-button {
    align-self:self-end;
    background-color: #ff4d4f;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
}

.delete-button:hover {
    background-color: #ff7875;
}

.table-header-season{
    display: grid; /* Flex yerine grid kullanıldı */
    grid-template-columns: 1fr 1fr 1fr ; /* Kolon genişlikleri */
    background-color: #320037;
    color: #f5f0ff;
    padding: 10px;
    border-radius: 10px;
    font-family: 'Trebuchet MS', sans-serif;
    font-weight: bold;
    font-size: 20px;
}
.add-season-section {
    background-color: #fefefe;
    padding: 20px;
    width: 400px;
    height: 300px;
    border-radius: 10px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}

.section-title {
    background-color: #320037;
    color: #f5f0ff;
    padding: 10px;
    text-align: center;
    border-radius: 10px;
    font-family: 'Trebuchet MS', sans-serif;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.seasons-table {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 400px;
    overflow-y: auto;
}

.table-header {
    display: grid;
    grid-template-columns: 1fr 1fr; /* İki eşit sütun */
    font-weight: bold;
    font-family: 'Trebuchet MS', sans-serif;
    font-size: 24px;
    color: #ffffff;
    border-bottom: 2px solid #ccc;
    padding-bottom: 5px;
}

.season-item {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; /* İki eşit sütun */
    align-items: center; /* Dikey hizalama */
    background-color: #fefefe;
    color: #333;
    height: 45px;
    transition: background-color 0.3s;
    font-family: 'Trebuchet MS', sans-serif; /* Yumuşak hatlı font */
    font-weight: bold; /* Normal kalınlık */
    font-size: 20px; /* Yazı boyutunu büyüttüm */
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 7px 7px  rgba(0, 0, 0, 0.1);
}

.add-season-title {
    font-family: 'Trebuchet MS', sans-serif;
    font-size: 25px;
    font-weight: bold;
    color: #320037;
    margin-bottom: 10px;
}

.league-select,
.season-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
}

.add-button {
    width: 50%;
    margin-left: 105px;
    margin-top: 35px;
    font-weight: bold;

    padding: 10px;
    background-color: #320037;
    color: #fff;
    font-family: 'Trebuchet MS', sans-serif;
    font-size: 24px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.add-button:hover {
    background-color: #250027;
}

.error-message {
    color: red;
    font-size: 14px;
    margin-top: 10px;
}

.success-message {
    color: green;
    font-size: 14px;
    margin-top: 10px;
}

/* Mobil görünüm */
@media (max-width: 768px) {
    .seasons-container {
        grid-template-columns: 1fr; /* Tek sütuna geç */
    }

    .add-season-section, .existing-seasons {
        margin-top: 20px;
        margin-left: -10px;
        width: 100%; /* Alt tarafa yerleştir */
    }
}
