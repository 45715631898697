








/* HOME CSS    */








.layout {
display: flex;
flex-direction: column;
gap: 0px;
padding: 0px;
max-width: 1600px; /* Maksimum genişlik */
width: 100%; /* Genişliği %100 olarak ayarla */
margin: 0 auto; /* Ekranda ortala */
box-sizing: border-box;
}

/* TABLOLARIN BAŞLIKLARI */
.table-header-home {
background: linear-gradient(to right, #3a0078, #1c66a3);
padding: 15px 0px 15px 10px; /* Yükseklik için padding azaltıldı */
text-align: left;
border-top-left-radius: 8px;
border-top-right-radius: 8px;
}

.table-header-home h2 {
margin: 0; /* Varsayılan margin'i kaldır */
font-family: "Raleway", sans-serif;
font-size: 20px; /* Font boyutu küçültüldü */
color: white;
font-weight: bolder;
white-space: nowrap; /* Metni tek satırda tut */
}



/* Container ayarları */
.little-match-container {
display: flex;
flex-wrap: wrap;
gap: 10px 4px; /* Kutular arasındaki boşluk */
justify-content: center;
width: 100%;
margin: 10px 0px;
align-items: center;
flex-wrap: wrap; /* Kutular iki satıra sarılır */
justify-content: space-between; /* Boşlukları eşit dağıtır */
}

/* MAÇ SONUÇLARI KUTULARI */
.box {
border: 1px solid #ddd;
border-radius: 8px;
padding: 0px;
text-align: center;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
cursor: pointer;
transition: transform 0.2s;
flex: 0 1 calc(20% - 4px); /* 18% genişlik: 5 kutu bir satıra sığar */
background-color: #ffffff;
color: #37043c;
height: 65px;
box-sizing: border-box;
font-size: 9px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
overflow: hidden;
}


.team-container-home {
display: flex;
align-items: center;  
justify-content: space-around;
}


/* Logo ayarları */
.team-logo2-home {
width: 30px;
height: 30px;
object-fit: contain;
margin: 0 auto;
text-align: left;
}



.box h3 {
font-family: 'Raleway', sans-serif;
font-weight: 400;
font-size: 13px;
font-weight: 400;
margin: 0px 0;
padding: 10px;
text-align: left;
margin-top: 0px; /* İstersen ince ayar yap */

}

.box:hover {
background: linear-gradient(to right, #3ea7ff, #963cff); /* Gradient arka plan */
color: white; /* Metin rengi beyaz */
transition: background 0.1s ease, color 0.1s ease; /* Yumuşak geçiş efekti */
transform: scale(1.05); /* Hover animasyonu */
}

.score-home {
font-family: "Raleway", sans-serif;
font-size: 18px;
letter-spacing: 1px;
font-weight: 400;
text-align: center;
color: #fff;
padding: 0px 0px;
border-radius: 5px;
background-color: #320073;
border: 2px solid black;
box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
width: 40px; /* Skor için sabit genişlik */



  
}

/* 3 Tablo */

.main-tables {
display: grid;
grid-template-columns: 1fr 2fr 1fr; /* Varsayılan: 3 sütun düzeni */
gap: 2px;
margin: 0 auto;
/* max-width: 1600px;  İçeriğin maksimum genişliği */
box-sizing: border-box;
}

/* Tabloların sıralanması */
.fixture-table {
grid-column: 1; /* 1. sütun */
z-index: 3;
}

.table-home {
grid-column: 2; /* Orta sütun */
z-index: 1;
}

.league-table-container {
grid-column: 3; /* 3. sütun */
z-index: 2;
}





/* Genel Tablo Düzeni */
.grouped-matches-table-home {
display: grid;
grid-template-columns: 1fr; /* Tüm genişliği kaplayacak şekilde */
gap: 0px;
/* padding: 20px; */
justify-content: center;
box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
align-items: stretch;
/* background-color: #f3f4f6; */
/* max-width: 1400px; Maksimum genişlik */
margin: 0 auto; /* Ortalansın diye */
}

/* Tarih Başlıkları */

.date-header-cell {

color: #000;
font-weight: bold;
font-size: 16px;
text-align: center;
padding: 16px;
gap: 20px;
border-radius: 8px;
text-transform: uppercase;
margin-bottom: 10px;
width: 100%;
}

/* Maç Satırları */
.match-row-home {
background-color: #fff;
border-radius: 12px;
padding: 0px;
text-align: center;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
transition: transform 0.2s, box-shadow 0.2s;
display: grid;
justify-items: stretch;
height: 80px;
grid-template-columns: 5fr 2fr 5fr; /* Takımlar ve saat */
align-items: center;
margin-bottom: 20px;
gap: 0px; /* İç öğeler arasındaki boşluk */
}

.match-row-home:hover {
transform: scale(1.07); /* Hover efekti */
box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
background: linear-gradient(135deg, #6c1d77, #c13584);
color: white; /* Hover sırasında metin rengi */
}

.team-cell-home {
align-items: center;
font-size: 14px; /* Yazı boyutunu sabit tut */
font-weight: bold;

}

.team-home {
font-size: 11px; /* Yazı boyutunu sabit tut */
font-weight: bold;
color: #333;
text-align: center; /* Metni ortala */
white-space: nowrap; /* Uzun takım isimlerini tek satırda tut */
overflow: hidden; /* Uzun metni taşırma */
text-overflow: ellipsis; /* Gerektiğinde ... ile kes */
line-height: 1.5; /* Satır yüksekliği */
height: 24px; /* Sabit yükseklik */
display: flex; /* Flexbox kullan */
align-items: center; /* Dikey ortalama */
justify-content: center; /* Yatay ortalama */
}

.match-row-home:hover .team-home {
color: #fff; /* Hover sırasında takım ismi */
}

/* Takım Logosu */
.team-logo-home {
width: 32px;
height: 32px;
border-radius: 20%;
padding: 10px;
object-fit: cover;
}

/* Saat Kutusu */
.match-time-home {
font-size: 16px;
font-weight: bold;
color: #444;
background: #f9fafb;
border: 1px solid #5d0098;
padding: 8px;
border-radius: 8px;
}

.match-row-home:hover .match-time-home {
background-color: white;
color: #5d0098; /* Hover sırasında saat kutusunda renk değişimi */
}






/* Tüm Listeyi Görüntüle Butonu */
.button-view-all {
background-color: #4a154b;
color: #fff;
text-align: center;
padding: 12px 20px;
border-radius: 8px;
font-size: 18px;
text-transform: uppercase;
font-weight: bold;
cursor: pointer;
transition: background-color 0.2s;
max-width: 400px;
margin: 20px auto;
display: block;
}

.button-view-all:hover {
background-color: #6c1d77;
}

.table-home {
padding: 20px;
background-color: #f0f0f0;
text-align: center;
position: relative;
}

/* Resim Galerisi */
.image-gallery {
display: flex;
justify-content: center;
align-items: center;
}

.image-container {
position: relative;
width: 100%; /* Sabit genişlik */
height: 400px; /* Sabit yükseklik */
border: 1px solid #ddd;
border-radius: 0px;
overflow: hidden; /* Resmin taşmasını engeller */
text-align: center;
}

.image-container img {
width: 100%; /* Konteynırın genişliğine uyar */
height: 100%; /* Konteynırın yüksekliğine uyar */
object-fit: cover; /* Resmi konteynırın alanını dolduracak şekilde büyütür */
}

.image-container p {
margin-top: 10px;
font-size: 14px;
color: #555;
}

/* Buton Konteyneri */
.button-container-home {
position: absolute;
bottom: 10px; /* Sağ alt köşeye hizalama */
right: 10px;
display: flex;
gap: 5px; /* Butonlar arasında boşluk */
}


/* Butonlar */
.nav-button {
background-color:#37043c; /* Yarı saydam siyah arka plan */
color: white;
border: none;
height: 13%;
border-radius: 5px;
padding: 10px 15px;
cursor: pointer;
z-index: 1;
}
.match-title-button:hover, .nav-button:hover {
background-color:#963cff; /* Hover'da daha koyu bir arka plan */
}


/* Match Title Butonu */
.match-title-button {
position: absolute;
bottom: 10px; /* Resmin altına hizala */
left: 10px; /* Sol tarafa yapışık */
background: linear-gradient(to right, #3a0078, #1c66a3);
color: white;
font-family: "Raleway", sans-serif;
font-size: 14px;
font-weight: bold;
padding: 10px 20px;
border-radius: 15px 15px 0px 0px;
cursor: pointer;
}

/* Tablo Konteyneri */
.fixture-table, .league-table-container {
display: table; /* Tablonun yüksekliği içeriğe göre ayarlanır */
width: 100%;
max-width: 600px;
margin: 20px auto;
border-radius: 8px;
background-color: #fff;
border: #ddd;

overflow: visible;
background-color: #f0f0f0;
}

/* Başlık */
.league-table-container-table-header {
background-color: #007bff;
color: white;
font-size: 18px;
font-weight: bold;
text-align: center;
padding: 15px;
border-bottom: 1px solid #ddd;
}

/* Tablo */
.league-table-container-league-table {
width: 100%;
border-collapse: collapse;
table-layout: fixed;
background-color: #fff;
}

.league-table-container-league-table thead th {
background-color: #f3f3f3;
font-size: 12px;
font-weight: bold;
word-wrap: break-word; /* Uzun kelimeleri kır */
white-space: normal; /* Taşmaları engelle */
text-align: center; /* İçeriği ortala */
text-transform: capitalize;
}

.league-table-container-league-table tbody td {
background-color: #fff;
font-family: 'Raleway', sans-serif;
font-size: 14px;
border-bottom: 1px solid #ddd;
word-wrap: break-word; /* Uzun kelimeleri kır */
white-space: normal; /* Taşmaları engelle */
text-align: center; /* İçeriği ortala */
text-transform: capitalize;
}
.league-table-container-league-table tr {
height: 40px; /* Satır yüksekliği */
}

.league-table-container-league-table th:nth-child(1),
.league-table-container-league-table td:nth-child(1) {
width: 9%; /* 3. sütuna özel genişlik */
}

.league-table-container-league-table th:nth-child(2),
.league-table-container-league-table td:nth-child(2) {
width: 61%; /* 3. sütuna özel genişlik */
}

.league-table-container-league-table th:nth-child(3),
.league-table-container-league-table td:nth-child(3) {
width: 9%; /* 3. sütuna özel genişlik */
}

.league-table-container-league-table th:nth-child(4),
.league-table-container-league-table td:nth-child(4) {
width: 9%; /* 3. sütuna özel genişlik */
}

.league-table-container-league-table th:nth-child(5),
.league-table-container-league-table td:nth-child(5) {
width: 12%; /* 3. sütuna özel genişlik */
font-size: 15px;
font-weight: bold;
}
/* Takım Bilgisi */
.league-table-container-team-info {
display: flex;
align-items: center;
gap: 20px;

}

.league-table-container-team-logo {
width: 25px;
height: 25px;
object-fit: contain;
}

/* İkinci Tablo Altındaki 2 Alt Tablo */
.sub-tables {
display: grid;
grid-template-columns: repeat(2, 1fr);
gap: 24px;
margin-top: 20px;
}

/* Sub Table */
.sub-table {

background-color: #f0f0f0;
text-align: left;
border-radius: 8px;
display: flex;
flex-direction: column;
align-items: center;

}

/* Genel Kart Stili */
.player-card-home {
display: grid;
grid-template-columns: 5fr 4fr; 
grid-template-rows: auto auto;
grid-template-areas:

"image stats"
"info info"
"button button";

border-radius: 0px;
overflow: hidden;
/* width: 330px; */
box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
font-family: Raleway, sans-serif;
/* margin:5px; */
}

/* Sol Tarafta İstatistikler */
.player-stats-home {
grid-area: stats;
padding: 15px;
background: linear-gradient(to top, #e6e6e6, #ffffff);
display: flex;
flex-direction: column;
align-items: center;
}

.player-stats-home ul {
list-style: none;
padding: 0;
margin: 0;
text-align: center;
}

.player-stats-home li {
font-size: 14px;
color: #242424;
font-weight: bold;
margin: 5px 0;
}

/* Oyuncu Resmi */
.player-image-home {
grid-area: image;
display: flex;
justify-content: center;
align-items: center;
background: linear-gradient(to top, #37007a, #0c011a);
}

.player-image-home img {
width: 100%;
height: 100%;
object-fit: cover;
border: 2px solid #ddd;
}

/* Oyuncu Bilgileri */
.player-info-home {
font-family: "Raleway", sans-serif;
grid-area: info;
padding: 15px;
text-align: center;
}

.player-info-home h2 {
font-family: "Raleway", sans-serif;
margin: 0px 0;
font-size: 18px;
color: #000;
}

.player-info-home p {
font-family: "Raleway", sans-serif;
margin: 0;
font-size: 14px;
color: #777;
}

/* Profil Butonu */
.view-profile-button-home {
grid-area: button;
background-color: #37043c;
color: white;
font-size: 14px;
font-weight: bold;
border: none;
border-radius: 5px;
padding: 10px;
cursor: pointer;
text-align: center;
}

.view-profile-button-home:hover {
background-color: #0056b3;
}

/* Tablo Ortak Stil */
.league-table {

width: 100%;
border-collapse: collapse;
text-align: left;
margin-top: 20px;
background-color: #fff;
border-radius: 8px;
}

.league-table th,
.league-table td {
padding: 10px;
font-size: 14px;
border-bottom: 1px solid #ddd;
}

/* Alternatif Satır Rengi */
.players-table tr:nth-child(even),
.league-table tr:nth-child(even) {
background-color: #f9f9f9;
}

/* Tablo Hover Efekti */
.league-table tbody tr:hover {
transform: scale(1.07);
border-radius: 8px;
font-size: 16px;
box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
background: linear-gradient(135deg, #6c1d77, #c13584);
color: white;
}

.players-table tbody tr:hover {
transform: scale(1.07);
border-radius: 8px;
font-size: 14px;
box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
background: linear-gradient(135deg, #6c1d77, #c13584);
color: white;

}

/* Logolar Hover Efektinde */
.players-table tbody tr:hover .team-logo,
.league-table tbody tr:hover .team-logo {
filter: brightness(0.9);
}

.league-table th {
background-color: #1c66a3;
color: white;
font-weight: bold;
}

.league-table td {
font-size: 14px;
color: #555;
}

.goal-table-container {

width: 100%;
gap: 5px;
background-color: #fff;
border-radius: 15px;
overflow: hidden;
border: 1px solid #ddd;
box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
font-family: Raleway, sans-serif;
}

/* Oyuncu Bilgisi */
.player-info {
font-family: "Raleway", sans-serif;
justify-content: center;
display: flex;
align-items: flex-start; /* Profil fotoğrafını ve metni hizalar */

}

.players-table {
font-family: "Raleway", sans-serif;
grid-template-columns: 5fr 4fr; 
width: 100%;
border-collapse: collapse;
text-align: left;

}

.players-table th,
.players-table td {
padding: 5px;
font-size: 14px;
border-bottom: 1px solid #ddd;
}

.players-table tr {
text-align: center;
}

.player-pic {
width: 100px; /* Profil fotoğrafı genişliği */
height: 100px; /* Profil fotoğrafı yüksekliği */
border-radius: 20%; /* Yuvarlak profil fotoğrafı */
object-fit: cover; /* Fotoğrafın düzgün görünmesi */
flex-shrink: 0; /* Profil fotoğrafının boyutunu korur */
}

.player-details {
font-family: "Raleway", sans-serif;
display: flex;
flex-direction: column; /* Oyuncu adı ve takım adını alt alta sıralar */
justify-content: center; /* Dikey hizalamayı düzenler */

}

.players-table td:first-child {
text-align: center; /* Sıra numarasını ortalar */
padding-right: 0px; /* Sıra numarası ile profil fotoğrafı arasındaki boşluk */
}

/* View Full List Butonu */
.view-full-list-button {
width: 100%;
padding: 10px;
background-color: #37043c;
color: white;
font-weight: bold;
font-size: 14px;
border: none;
cursor: pointer;
text-align: center;
border-radius: 0 0 5px 5px;
}

.view-full-list-button:hover {
background-color: #963cff;
}
.league-table-container-league-table tbody tr:hover {

transform: scale(1.07); /* Hover efekti */
border-radius: 8px;
font-size: 16px;
box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
background: linear-gradient(135deg, #6c1d77, #c13584);
color: white; /* Hover sırasında metin rengi */

}

/* Hücre İçindeki Metinler */
.league-table-container-league-table tbody tr:hover td  {
border-radius: 8px;
font-size: 16px;
background: transparent; /* Arka plan rengi gradient'e karışmasın */
color: white; /* Metin rengi beyaz */
}

@media screen and (max-width: 1500px) and (-webkit-min-device-pixel-ratio: 2) {
.main-tables {
grid-template-columns: 1fr 1fr; /* 2 sütunlu yapı */
grid-template-areas:
"table-home table" /* 2. tablo altta tam genişlikte */
"fixture-table league-table-container"; /* 1. ve 3. tablo yan yana */
}

.fixture-table {
grid-area: fixture-table;
}

.table-home {
grid-area: table-home;
}

.league-table-container {
grid-area: league-table-container;
}
}

/* Mobil Görünüm */
@media screen and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
.main-tables {
grid-template-columns: 1fr; /* Tek sütun */
grid-template-areas:
"fixture-table"
"table-home"
"league-table-container";
}

.fixture-table, .table-home, .league-table-container {
grid-column: 1; /* Tek sütun */
width: 100%;
}
.table-home,.layout{
padding: 0%;
}
.little-match-container {
flex-wrap: wrap; /* Küçük ekranlarda sarılmayı etkinleştir */
}

.box {
flex: 1 1 100%;
/* Mobilde kutucuklar %45 genişliğinde olacak */
}
.image-container {
display: none;
}
.main-tables {
grid-template-columns: 1fr; /* Tablolar alt alta */
}

.sub-tables {
grid-template-columns: 1fr; /* Alt tablolar alt alta */
}
}
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	/* CITY HOME */
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
.city-leagues-container {
    text-align: center;
  }
  
  .city-leagues-container h1 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .leagues-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(35%, 1fr)); /* Varsayılan masaüstü görünümü */
    gap: 20px;
    padding: 3%;
    justify-items: center;
  }
  
  .league-card {
    width: 85%;
    border: 1px solid #ddd;
    background: linear-gradient(135deg, #5a0075, #9b009b); /* Açık mora geçişli gradient */
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
  
  .league-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
  
  .league-card-image {
    width: 100%;
    height: 550px;
    object-fit: cover;
  }
  
  .league-card-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin: 40px 0;
    text-align: center;
    color: white;
  }
  
  /* Mobil Görünüm */
  @media screen and (max-width: 768px) {
    .leagues-grid {
      grid-template-columns: 1fr; /* Kartları tek sütun olarak düzenle */
      padding: 5%;
    }
  
    .league-card {
      width: 85%; /* Kartların genişliğini %100 yap */
    }
  
    .league-card-image {
      height: 170px; /* Resimlerin yüksekliğini küçült */
    }
  
    .league-card-title {
      font-size: 1.2rem; /* Yazı boyutunu küçült */
    }
  }
  	
	
	
	
	
	
	
	
	
	
	
	
	/* COMMUNİCATİON CSS */
	
	
	
	
	
	
	
	
	
	
	
	/* Header Ana Yapısı */
.header-banner {
    position: relative;
    height: 250px; /* Yükseklik */
    background: linear-gradient(45deg, #4facfe, #735cff); /* Mavi ve mor geçiş */
    display: flex;
    justify-content: center; /* Yatayda ortala */
    align-items: center; /* Dikeyde ortala */
    color: white; /* Yazı rengi */
    text-align: center;
    overflow: hidden;
}

/* Başlık Yazısı */
.banner-title {
    font-size: 60px; /* Büyük yazı */
    font-weight: bold; /* Kalın yazı */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Hafif gölge efekti */
    z-index: 2; /* Yazıyı üstte tut */
    position: relative;
    color: white;
}

/* Zikzak SVG */
.zigzag {
    position: absolute;
    bottom: 0; /* Zikzak SVG'yi alt kısma yerleştir */
    left: 0;
    width: 100%;
    height: auto;
    overflow: hidden;
}

.zigzag svg {
    display: block;
    width: 100%;
    height: 100%;
}
	
	
	
	
	
	
	
	
	
	/*        FİXTURE          */
	
	
	
	
	
	.fixture-container {
    max-width: 1200px; /* Daha kompakt bir genişlik */
    margin: 20px auto; /* Üstten biraz boşluk */
    padding: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #f9f9f9; /* Hafif arka plan rengi */
}

h2 {
    font-size: 24px; /* Daha belirgin başlık */
    color: #222; /* Daha koyu renk */
    margin: 20px 0;
    
    font-weight: bold;
    letter-spacing: 1px;
}

.fixture-date {
    background-color: #f4f4f4; /* Daha yumuşak arka plan rengi */
    padding: 12px;
    font-size: 18px;
    color: #333;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 15px;
    font-weight: 600;
}

.fixture-row {

    display: flex;
    flex-direction: row;
    justify-content: space-between; /* Eşit aralık */
    gap: 15px;
    align-items: center;
    background-color: #ffffff;
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
    border-radius: 8px;
    transition: all 0.3s ease; /* Daha pürüzsüz hover */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    border: 1px solid #ddd;

    
}

.fixture-row:hover {
    background: linear-gradient(to right, #3ea7ff, #963cff);
    color: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    transform: scale(1.02); /* Hafif büyütme */
}

.team-fixture {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    min-width: 200px; /* Takım isimleri için sabit genişlik */
    max-width: 200px; /* Fazla uzun isimleri sınırlamak için */
    text-align: left; /* İsimlerin sola hizalanması */
    justify-content: flex-start; /* Görsel tutarlılık için içerikleri sola hizala */
    overflow: hidden; /* Uzun metinleri taşırma */
    white-space: nowrap; /* Metni tek satırda tut */
    text-overflow: ellipsis; /* Taşan metinler için "..." göster */
}

.team-fixture img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}



.time-score-container {

    display: flex;
    flex-direction: column;
    align-items: center; /* Hem skorları hem de saati yatayda hizalar */
    justify-content: center; /* Dikeyde ortalar */
    gap: 5px; /* Skor ve saat arasında boşluk */
    min-width: 80px; /* Skor ve saat için sabit genişlik */
    text-align: center;
}




.time {
    font-size: 16px;
    font-weight: 600;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 8px 12px;
    color: #333;
    background-color: #f9f9f9;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    width: 60px; /* Sabit genişlik */
    text-align: center;


    
}

.score {
    font-family: "Raleway", sans-serif;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    background-color: #320073;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    width: 40px; /* Skor için sabit genişlik */
    text-align: center;


}



.stadium {
    font-size: 14px;
    color: #666;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    min-width: 150px; /* Stat bilgisi için sabit genişlik */
}



.fixture-row a {
    text-decoration: none;
    color: #0056b3;
    font-weight: 600;
    transition: color 0.3s ease;
}

.fixture-row a:hover {
    color: white;
}

.actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    min-width: 120px; /* Buton hizalaması için sabit genişlik */
}

.quick-view {
    background-color: #e5e5e5;
    color: #333;
    border: 1px solid #ddd;
    padding: 8px 15px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    transition: all 0.3s ease;
}

.quick-view:hover {
    background-color: #0056b3;
    color: white;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
}

.league-logo {
    font-size: 14px;
    color: #888;
    margin-top: 5px;
    text-align: center;
    font-weight: 500;
}
	
	
	
	
	
	
	/*         FOOTER           */
	
	
	
	
	
	
	
	
	body {
    margin: 0;
    padding: 0;
    height: 100%;
}
.main-content{
     
    margin-top: auto;
}
/* Footer'ın stilini belirleyin */
.site-footer {
    background-color: #37043c; /* Koyu bir arka plan rengi */
    color: white; /* Yazı rengi */
    text-align: left; /* Ortalamak */
    padding: 20px 0; /* Üst ve alt boşluk */
    position: relative;
    bottom: 0;
    width: 100%;
    display: flex; /* Flexbox düzeni aktif hale gelir */
    align-items: center;
    z-index: 1;
}

/* Footer içeriği */
.footer-content {
    max-width: 1200px; /* Maksimum genişlik */
    margin: 0 auto; /* Ortalamak */
    padding: 0 15px;
}

.footer-content-p, .footer-content-a {
    margin: 10px; /* Fazla boşlukları ortadan kaldırmak */
    color: white;
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
    text-decoration: none;
    text-wrap: nowrap;
}

@media (max-width: 1008px) {
    .site-footer {
        display: flex;
        flex-direction: row; /* Mobilde içeriği dikey hizala */
        align-items: center; /* Ortala */
        justify-content: center;
        padding: 20px 0; /* Mobilde biraz daha sıkıştır */
    }

    .footer-content {
        width: 100%; /* İçeriği tam genişlikte yap */
        text-align: center; /* Yazıları ortala */
        margin-bottom: 20px; /* Her içerik arasında boşluk bırak */
    }

    .footer-content-p {
        font-size: 16px; /* Başlık yazı boyutunu küçült */
    }

    .footer-content-a {
        font-size: 14px; /* Linklerin yazı boyutunu küçült */
        padding: 5px; /* Linklerin arasındaki boşlukları azalt */
    }
}

	
	
	
	
	
	
	
	/*            HEADER                    */
	
	
	
	
	
	
	
	
	/* Genel Header Konteyner */
.header-container-h {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-family: "Arial", sans-serif;
  }
  
  /* Üst Menü */
  .top-header-h {
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #ecebeb;
    border-bottom: 1px solid #ddd;
  }
  
  .top-right-buttons-h {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
  }
  
  /* Şehir Değiştir Butonu */
  .city-change-container-h {
    position: relative;
  }
  
  .city-change-btn-h {
    background-color: #37043c;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .city-change-btn-h:hover {
    background-color: #ca71d2;
  }
  
  /* Dropdown Menü */
  .city-dropdown-h {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #ca71d2;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    width: 180px;
    z-index: 999;
    border-radius: 5px;
  }
  
  .city-item-h {
    padding: 10px;
    text-decoration: none;
    color: white;
    font-size: 14px;
    border-bottom: 1px solid #37043c;
    font-family: "Trebuchet MS", sans-serif;
    transition: background-color 0.3s ease;
    text-align: left;
    display: block;
  }
  
  .city-item-h:hover {
    background-color: #9e4cba;
  }
  
  /* Eski Siteye Dön Butonu */
  .old-site-btn-h {
    background-color: #37043c;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .old-site-btn-h:hover {
    background-color: #ca71d2;
  }
  
  /* Ana Menü */
  .main-header-h {
    background: #37043c;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 0 0px;
  }
  
  .header-logo-h img {
    width: 150px;
    z-index: 1000;
    position: relative;
  }
  
  /* Menü Linkleri */
  .nav-links-h {
    display: flex;
    gap: 30px;
    list-style: none;
  }
  
  .nav-links-h li {
    display: flex;
    gap: 30px;
    list-style: none;
  }
  
  .nav-links-h a {
    text-decoration: none;
    color: white;
    font-size: 14px;
    transition: color 0.3s ease;
    font-family: "Trebuchet MS", sans-serif;
  }
  
  .nav-links-h a:hover {
    color: #ca71d2;
  }
  
  /* Sağ Bölüm */
  .right-section-h {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  /* Sidebar (Mobil için) */
  .sidebar-h {
    position: fixed;
    top: 0;
    right: -100%;
    width: 250px;
    height: 100%;
    background-color: #37043c;
    color: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    transition: right 0.3s ease;
    z-index: 1000;
  }
  
  .sidebar-h.open {
    right: 0;
  }
  
  .close-sidebar {
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    align-self: flex-end;
    cursor: pointer;
  }
  
  .sidebar-link {
    color: white;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 0;
    text-transform: uppercase;
    transition: color 0.3s ease;
  }
  
  .sidebar-link:hover {
    color: #ca71d2;
  }
  
  /* Sidebar içindeki şehir ve ligler */
  .sidebar-section {
    display: flex;
    flex-direction: column;
  }
  
  .sidebar-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  
  /* Alt Menü */
  .sub-header-h {
    width: 100%;
    background: #ecebeb;
    text-align: center;
    border-top: 1px solid #ddd;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .sub-menu-h {
    display: flex;
    justify-content: center;
    gap: 50px;
    list-style: none;
    font-size: 14px;
    font-weight: bold;
  }
  
  .sub-menu-h a {
    text-decoration: none;
    color: black;
    transition: color 0.3s ease;
  }
  
  .sub-menu-h a:hover {
    color: #37043c;
  }
  
  /* Hamburger Menü (Mobil için) */
  .hamburger-menu {
    display: none;
    font-size: 24px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
  }
    
  .right-section-h {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .sign-in-btn-h {
    background: white;
    color: black;
    padding: 8px 12px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .sign-in-btn-h:hover {
    background: #ddd;
  }
  
  .search-icon-h {
    color: white;
    font-size: 18px;
    cursor: pointer;
  }
  /* Responsive Tasarım */
  @media (max-width: 768px) {
    .nav-links-h {
        display: none; /* Menü gizlensin, sadece hamburger menü görünsün */
    }
  
    .hamburger-menu {
        display: block; /* Mobilde hamburger menü görünsün */
    }
  
    .sub-menu-h {
        flex-wrap: wrap;
        justify-content: center;
    }
    .top-header-h {
      flex-direction: column;
      gap: 10px;
    }
    .header-logo-h img {
      width: 100px;
      margin-left: 10%;
      z-index: 1;
    }
  }
  
	
	
	
	
	
	
	
	
	
	
	/*              HOME 2                  */
	
	
	
	
	
	
	
	
	/* Şehir ve Ligler Tablosu */
  .league-container {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 50px;
  }
  
  .league-table-title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .city-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
  
  .city-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .city-button {
    background-color: #320037;
    color: white;
    width: 50%;
    height: 80px;
    border: none;
    padding: 10px 20px;
    font-size: 25px;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .city-button:hover {
    background-color: #501e54;
  }
  
  .league-list {
    margin-top: 10px;
  }
  
  .league-button {
    background-color: #cd2473;
    color: white;
    border: 1px solid #ddd;
    width: 300px;
    height: 60px;
    padding: 8px 15px;
    font-size: 20px;
    margin-top: 5px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .league-button:hover {
    background-color: #e2619d;
  }
  

  .map-container {
    text-align: center;
    position: relative;
  }
  
  .map-image {
    width: 80%;
    max-width: 800px;
  }
  
  .map-wrapper {
    position: relative;
    display: inline-block;
    margin-top: 20px;
  }
  
  .city-marker {
    position: absolute;
    transform: translate(-50%, -50%);
    font-size: 24px;
    color: red;
    cursor: pointer;
    animation: bounce 1.5s infinite;
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translate(-50%, -50%) scale(1);
    }
    50% {
      transform: translate(-50%, -60%) scale(1.2);
    }
  }
  
  .city-marker:hover {
    color: darkred;
    font-size: 28px;
  }
  
	
	
	
	
	
	
	
	/*            LEAGUE STANDİNGS.             */
	
	
	
	
	
	
	
	
	
	.centered-container-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px; /* Dropdownlar arasındaki boşluk */
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px; /* Mobilde daha iyi görünmesi için sınırla */
    margin: 10px auto;
    justify-content: left;
    max-width: 200px;
}

.centered-container-2 select {
    width: 100%; /* Genişliği tam yap */
    max-width: 400px; /* Maksimum genişlik ayarla */
    padding: 12px 15px; /* Daha geniş tıklanabilir alan */
    font-size: 16px; /* Yazı boyutunu artır */
    border: 1px solid #ccc; /* Kenarları belirginleştir */
    border-radius: 8px; /* Köşeleri yuvarla */
    background-color: #fff;
    color: #333;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    appearance: none; /* Tarayıcı varsayılan ok stilini kaldır */
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    margin-bottom: 0;
}

.centered-container-2 select:hover {
    border-color: #007bff;
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
}

.centered-container-2 select:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.centered-container-2 select::-ms-expand {
    display: none; /* Internet Explorer'da ok stilini kaldır */
}

.centered-container-2::after {
    font-size: 14px;
    color: #333;
    position: absolute;
    right: 15px;
    pointer-events: none;
}


.table-container {
    display: block;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 10px;
    background-color: #f1f1f1;
    border-radius: 8px;
    overflow-x: auto;
    max-width: 100%;
}

.table-container table {
    width: 1300px; /* Daha geniş yapar, % değeri artırılabilir */
    margin: 0 auto;
    border-collapse: collapse;
    font-size: 16px; 
}


.table-container h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #37043c;
}

.table-container th, .table-container td {
    padding: 10px 15px;
    text-align: center;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
}
.table-container td{
    background-color: #f1f1f1;
}

.table-container th {
    background-color: #e3e2e3;
    font-weight:bolder;
    color: #876d89;
}

.table-container tr:nth-child(even) {
    background-color: #f9f9f9;
}

.table-container tr:hover {
    background-color: #f1f1f1;
}

.table-container td:first-child, th:first-child {
    text-align: left;
}

.table-container td.points {
    font-weight: bold;
    color: #37043c;
}

td .form {
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    margin: 0 2px;
    font-size: 12px;
    color: #fff;
}

td .form.win {
    background-color:#08d772;
}

td .form.draw {
    background-color: #c5b3c5;
}

td .form.loss {
    background-color:#e91f5f;
}

.little-logo-style {
    width: 30px;
}
	
	
	
	
	
	
	
	
	
	
	
	/*                LOGİN                  */
	
	
	
	
	
	
	
	
	
	
	.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5;
    font-family: 'Trebuchet MS', sans-serif;
  }
  
  h2 {
    color: #320037;
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .login-form {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 300px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    font-size: 16px;
    color: #320037;
    display: block;
    margin-bottom: 5px;
  }
  
  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .login-button {
    width: 100%;
    padding: 10px;
    background-color: #320037;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .login-button:hover {
    background-color: #4a005f;
  }
  
  .error-message {
    color: red;
    font-size: 14px;
    margin-top: 10px;
  }
  .toggle-password-button {
    margin-top: 5px;
    padding: 5px 10px;
    font-size: 14px;
    background-color: #008080;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .toggle-password-button:hover {
    background-color: #005959;
  }
  
	
	
	
	
	
	
	
	
	
	
	/*             MATCH 2                  */
	
	
	
	
	
	
	
	
	
	
	
	
	
	.layout-container {
    display: grid;
    grid-template-columns: 1fr 2fr; /* Masaüstü: Sol ve sağ bölümler */
    grid-template-rows: auto 1fr; /* Üst ve alt bölümler */
    gap: 20px;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
  }
  
  /* Sol Bölüm (Tablolar) */
  /* Ana Yapı */
.table {
    background-color: white;
    border: 1px solid #ddd;
    padding: 0;
    border-radius: 8px;
    overflow: hidden; /* Köşe yuvarlaklığını korur */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .table-bottom {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }
  /* Tablo Stili */
  .info-table-match {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
    text-align: center;
    height: 73px;
  }
  /* Başlık */
  .info-table-match th {
    background: linear-gradient(to right, #3ea7ff, #963cff);
    color: white;
    padding: 15px;
    font-size: 16px;
    text-align: center;
  }
  /* Satır Stili */
  .info-table-match td {
    border-bottom: 1px solid #ddd;
    background-color: white;
    padding: 15px;
    font-size: 14px;
    color: #37043c;
    text-align: left;
    transition: background 0.3s ease, color 0.3s ease;
    cursor: pointer;
  }
  /* Hover Efekti */
  .info-table-match td:hover {
    background: linear-gradient(to right, #3ea7ff, #963cff);
    color: white;
  }
  /* Bold Metin */
  .info-table-match b {
    display: inline-block;
    padding: 5px;
    font-weight: bold;
  }
  /* Bağlantıların Rengi */
  .info-table-match a {
    text-decoration: none;
    color: inherit;
  }
  
  
  .table-top {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    height: 112px;
  }
  .table-bottom table {
    width: 100%;
    border-collapse: collapse;
  }
  .table-bottom th {
    background: linear-gradient(to right, #3ea7ff, #963cff);
    color: white;
    padding: 10px;
    text-align: left;
    font-size: 16px;
  }
  .table-bottom td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  .table-bottom td:hover{
    background: linear-gradient(to right, #3ea7ff, #963cff);
    color: white;
  }
  .table-bottom a {
    text-decoration: none;
    color: #333;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .table-bottom a:hover {
    color: white;
    font-weight: bold;
  }
  .table-bottom tr:hover {
    background-color: #f0f0f0;
    cursor: pointer;
  }
  .table-bottom .score-match {
    background-color: #37043c;
    color: white;
    padding: 5px 8px;
    border-radius: 7px;
    font-size: 14px;

    display: inline-block;
    font-weight: bolder; 
    text-align: center;
    border: 2px solid #ffffff; /* Beyaz kenarlık */
    min-width: 40px; /* Minimum genişlik */
  }
  
  /* Sağ Üst Büyük Kutu */
  .large-box-match {
    background-color: #37043c;
    color: white;
    width: 100%; /* Tam genişlik */
    max-width: 1200px; /* Maksimum genişlik */
    height: auto; /* Yükseklik içeriğe göre ayarlanır */
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-family: Arial, sans-serif;
    margin: 0 auto; /* Ortalamak için */
    box-sizing: border-box; /* Padding genişliğe dahil */
  }
  
  /* Header */
  .match-header-match {
    display: grid; /* Flex yerine grid kullanarak sabitleme */
    grid-template-columns: 1fr auto 1fr; /* Sol takım - skor - sağ takım */
    align-items: center; /* Dikey hizalama */
    text-align: center;
    gap: 20px; /* Elemanlar arasında boşluk */
    max-width: 800px; /* Maksimum genişlik */
    margin: 0 auto; /* Ortalamak için */
  }
  .team-match {
    display: flex;
    align-items: center;
    justify-content: center; /* İçeriği ortala */
    font-weight: bolder;
    font-size: 20px;
    gap: 10px;
    white-space: nowrap; /* Taşmayı engelle */
  }
  .team-logo-match {
    width: 60px;
    height: auto;
  }
  .score-match-2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .score-box-match {
    background: white;
    color: #37043c;
    font-weight: bolder;
    font-size: 42px;
    padding: 5px 20px;
    border-radius: 8px;
    min-width: 100px;
    height: 60px;
    text-align: center;
    box-sizing: border-box;
  }
  /* Goal Section */
  .match-goals-match {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
  }
  .goals-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 14px;
  }
  /* Timeline */
  .match-timeline-match {
    position: relative;
    height: 30px;
    background: #fff;
    margin: 10px 0;
    margin-top: 40px;
    border-radius: 5px;
  }
  .goal-marker {
    position: absolute;
    bottom: 5px;
    transform: translateY(-8px);
    width: 12px;
    height: 12px;
    background-image: url('../assets/logo192.png');
    background-size: cover;
    background-position: center;
    border: 2px solid #fff;
    border-radius: 50%;
  }
  .goal-marker.first-team {
    top: 30%; /* Birinci takım için biraz yukarı kaydır */
  }
  .goal-marker.second-team {
    top: 70%; /* İkinci takım için biraz aşağı kaydır */
  }
  .goal-marker .goal-ball {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  .goal-tooltip {
    display: none; /* Varsayılan olarak gizli */
    position: absolute;
    bottom: 20px; /* Topun biraz üstünde görünecek */
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap; /* Taşmayı engelle */
    z-index: 10;
  }
  
  .goal-marker:hover .goal-tooltip {
    display: block; /* Hover durumunda göster */
  }
  .team-logos-timeline {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 0px; /* Çizginin soluna taşı */
    gap: 0px;
    top: -18px;
  }
  /* Takım logoları */
  .team-logo-timeline {
    width: 30px;
    height: 30px;
    object-fit: contain;
  }
  .timeline-line {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #ccc;
    transform: translateY(-50%);
  }



  .match-highlights-container {
    display: flex;
    justify-content:flex-start;
    gap: 20px;
    padding: 20px;
    border-radius: 8px;
  }
  
  .highlight-card {
    display: flex; /* İçeriği hizalamak için */
    flex-direction: column; /* Thumbnail üstte, info altta */
    align-items: center; /* Yatayda ortala */
    text-decoration: none; /* Link altı çizgisini kaldır */
    color: inherit; /* Yazı rengini devral */
    background-color: #37043c; /* Arkaplan rengi */
    border-radius: 8px; /* Kenar yuvarlaklığı */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Hafif gölge efekti */
    padding: 10px; /* İç boşluk */
    gap: 10px; /* Thumbnail ve bilgi arasında boşluk */
    overflow: hidden;
    width: 180px;
    height: auto;
  }
  
  .highlight-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
  
  .highlight-thumbnail {
    width: 100%;
    max-width: 200px; /* Görselin maksimum genişliği */
    height: auto;
    border-radius: 8px; /* Köşe yuvarlaklığı */
  }
  
  
  .highlight-info {
    text-align: center; /* Metni ortala */
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px; /* Satırlar arası boşluk */
  }
  
  .highlight-info .platform {
    font-size: 12px;
    font-weight: bold;
    color: #888;
    text-transform: uppercase;
  }
  
  .highlight-info h4 {
    font-size: 16px;
    font-weight: bold;
    color: white; /* Yazı rengi */
    margin: 0; /* Boşluk kaldır */
  }
  
  
  
  
  
  /* Sekmeler Alanı */
  .tabs-container {
    grid-column: span 2;
  }
  
  .tabs-buttons {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .tabs-buttons button {
    background-color: #e0e0e0;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
  }
  
  .tabs-buttons button.active {
    background-color: #37043c;
    color: white;
  }
  
  .tabs-content {
    background-color: white;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
  }
  
  .tab-content {
    font-size: 16px;
    color: #333;
  }

 .tab-content-1 {
    display: grid;
    grid-template-columns: 2fr 1fr; 
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  .man-of-the-match {
    background: linear-gradient(to bottom, #3590da, #12466a);
    color: white;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
  }
  .motm-player p, .motm-player h2{
    font-size: 30px;
  }
  .motm-player img {
    width: 150px;
    height: auto;
    border-radius: 50%;
    margin: 10px 0;
  }
  .view-more-btn {
    background-color: #37043c;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    margin-top: 10px;
  }
  .match-comment-container {
    background-color: #f9f9f9; /* Hafif gri arkaplan */
    border: 1px solid #ddd; /* İnce çerçeve */
    padding: 20px;
    margin: 20px 0;
    border-radius: 10px; /* Köşe yuvarlaklığı */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Hafif gölge */
  }
  .comment-header {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #37043c; /* Mor renk */
    text-align: left;
  }
  .match-comment {
    font-size: 16px;
    line-height: 1.5;
    color: #333; /* Koyu gri metin */
    text-align: justify; /* Metni iki yana yasla */
    margin: 10px 0;
  }
  .small-boxes {
    display: flex;
    flex-direction: column; /* Fotoğrafları alt alta sıralar */
    max-width: 300px; /* Kutuların maksimum genişliği */
    margin: 0 auto; /* Ortalamak için */
    gap: 20px;
  }
  .small-box {
    background-color: #f0f0f0; /* Kutular için arka plan rengi */
    border: 1px solid #ddd; /* Kutulara hafif kenarlık */
    border-radius: 8px; /* Köşeleri yuvarlatır */
    overflow: hidden; /* İçeriği kutu içine sığdırır */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Hafif gölge efekti */
  }
  .small-box img {
    width: 100%; /* Fotoğrafın genişliğini kutuya göre ayarlar */
    height: auto; /* Oranları bozmadan küçültür */
    display: block; /* Fotoğrafı kutu içine düzgün yerleştirir */
    object-fit: cover; /* Fotoğrafı kutu boyutuna göre kırpar */
  }



  .tab-content-2 {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 10px;
  }
  /* Ana container */
  .lineup-container {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr; /* Sol - Orta - Sağ */
    gap: 20px;
    text-align: center;
  }
  /* Oyuncu Listesi */
  .team-players {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .team-players h3 {
    color: #37043c;
    font-size: 18px;
    margin-bottom: 5px;
  }
  .player {
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    font-size: 14px;
    color: #333;
  }
  /* Orta Kısım - Futbol Sahası */
  .field-container {
    position: relative;
    background-color: #2f8f2f;
    border-radius: 10px;
    height: auto;
  }
  .field-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  .team-logo-field {
    position: absolute;
    width: 60px; /* Logo boyutu */
    height: 60px;
    object-fit: contain; /* Görüntüyü sığdır */
    z-index: 10; /* Oyuncu noktalarının üstünde olsun */
  }
  .team-logo-field.top-logo {
    top: -5%; /* Üst kısma yerleştir */
    left: 50%; /* Yatayda ortala */
    transform: translateX(-50%); /* Tam ortalamak için */
  } 
  .team-logo-field.bottom-logo {
    bottom: -5%; /* Alt kısma yerleştir */
    left: 50%; /* Yatayda ortala */
    transform: translateX(-50%); /* Tam ortalamak için */
  } 
  .position {
    position: absolute;
    background-color: #3ea7ff; /* Varsayılan renk */
    color: white;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    border: 2px solid #fff;
  }
  /* Birinci Takım Renkleri */
  .position.first-team {
    background-color: #3ea7ff; /* Mavi */
  }
  /* İkinci Takım Renkleri */
  .position.second-team {
    background-color: #ff3e3e; /* Kırmızı */
  }
  .player-row-field {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px; /* Elemanlar arasında boşluk */
    padding: 10px;
    border-bottom: 1px solid #ddd;
    background-color: white;
    transition: background-color 0.3s ease;
  }
  .player-row-field:hover {
    background-color: #f0f0f0; /* Hover efekti */
  }
  .player-image-field {
    width: 70px;
    height: auto;
    object-fit: cover; /* Görselin düzgün sığması */
  }
  .player-info-field {
    display: flex;
    flex-direction: column;
    flex-grow: 1; /* Esnek genişlik */
  }
  .player-number-field {
    font-weight: bold;
    font-size: 14px;
    color: #37043c;
  }
  .player-name-field {
    font-size: 16px;
    font-weight: bold;
  }
  .player-country-field {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    color: gray;
  }
  .flag-icon-field {
    width: 20px;
    height: 15px;
    object-fit: contain;
  }
  .player-minute-field {
    font-weight: bold;
    color: #e63946;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .minute-icon-field {
    width: 20px; /* Genişlik */
    height: 20px; /* Yükseklik */
    background-image: url('../assets/logo192.png'); /* Top ikon resmi */
    background-size: cover; /* Görselin kutuya tam sığması */
    background-repeat: no-repeat; /* Görselin tekrarlanmasını engelle */
    display: inline-block; /* Görselin görünmesi için inline-block */
  }
  .position-t2 {
    position: absolute;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .player-image-t2 {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 5px;
  }
  
  .player-name-t2 {
    font-size: 12px;
    font-weight: bold;
    color: #333;
  }
  /* Responsive Görünüm */


  .tab-content-3 {
    margin: 20px 0;
    font-family: Arial, sans-serif;
  }
  
  /* Genel Sekme Stili */
.tabs-header {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .tab-button {
    background-color: #f4f4f4;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: bold;
    color: #333;
    border-radius: 5px;
    transition: background 0.3s;
  }
  
  .tab-button.active {
    background-color: #37043c;
    color: white;
  }
  
  /* Match Stats Container */
  .match-stats-container {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    background-color: #37043c;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
  }
  .match-stats-title-match {
    font-size: 28px;
    font-weight: bold;
    color: white;
    margin-bottom: 15px;
  }
  .stats-table-match {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    background-color: white;
  }
  .team-header-match {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background-color: white;
  }
  .team-logo-match-3 {
    width: 30px;
    height: auto;
    padding: 5px;
    vertical-align: middle;
  }
  .stats-table-match th {
    background-color: white;
    padding: 10px;
    font-size: 16px;
    color: #37043c;
    font-weight: bold;
  }
  .stats-table-match td {
    padding: 12px 15px;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    background-color: inherit; /* Arkaplan rengini tr'den alır */
  }
  .stats-table-match tr:hover {
    background: linear-gradient(to right, #3ea7ff, #963cff);
    color: white;
  }

  /* BİRİNCİ TABLO */
.head-to-head-container {
    padding: 20px;
    background-color: #37043c;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
  }
  /* Başlık Stili */
  .section-title-head-to-head {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: white;
    margin-bottom: 20px;
  }
  /* Stat Kutuları */
  .stat-boxes-head-to-head {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .stat-box-head-to-head {
    background-color: #f4f4f4;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-size: 16px;
    color: #333;
  }
  .stat-box-head-to-head h4 {
    margin-bottom: 8px;
    font-weight: bold;
    color: #37043c;
  }
  .head-to-head {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    position: relative;
    left: 8%;
    width: 80%;
  }
  /* Üst Kısım: Takım İsimleri */
  .head-to-head-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .team-name-match {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: bold;
    font-size: 18px;
    color: #37043c;
  }
  .team-name-match img {
    width: 40px;
    height: 40px;
  }
  /* Alt Kısım: Barlar ve Sayılar */
  .stats-container-match {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  }
  .team-stats-match {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
  }
  .bar-stat-match {
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: space-between;
    font-size: 15px;
    color: #37043c;
    font-weight: bolder;
    gap: 20px;
  }
  .bar-match {
    background-color: #eee;
    width: 200px;
    height: 25px;
    overflow: hidden;
  }
  .filled-bar-match {
    background: linear-gradient(to right, #1b1048, #3ea7ff);
    height: 100%;
  }
  .center-stats-match {
    flex: 0.5;
    text-align: center;
  }
  .played-match {
    font-size: 48px;
    font-weight: bold;
    color: #37043c;
  }
  .draws-match {
    font-size: 16px;
    font-weight: bold;
    color: #37043c;
  }
  .draws-match span {
    color: #333;
    font-weight: normal;
  }

  /* İKİNCİ TABLO */
  .recent-meetings {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    border: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0);
  }
  .section-title-rm {
    text-align: center;
    font-size: 25px;
    color: #37043c;
    font-weight: bold;
  }
  /* Maç Listesi */
  .meeting-list-rm {
    display: flex;
    flex-direction: column;
  }
  /* Tekil Maç Kartı */
  .meeting-card-rm {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    padding: 15px;
    border: 1px solid #f4f4f4;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  .meeting-card-rm:hover{
    background: linear-gradient(to right, #3ea7ff, #963cff);
    color: white;
  }
  /* İç öğelerin rengini hover durumunda değiştir */
.meeting-card-rm:hover .arrow-icon-rm,
.meeting-card-rm:hover .meeting-venue-rm,
.meeting-card-rm:hover .meeting-date-rm,
.meeting-card-rm:hover .team-name-rm,
.meeting-card-rm:hover .meeting-info-rm {
  color: white;
}
/* Logo için ek bir kontrol: hover efekti */
.meeting-card-rm:hover .team-logo-rm {
  filter: brightness(0) invert(1); /* Logoyu beyaza dönüştürür */
} 
  .meeting-date-rm {
    font-size: 12px;
    color: #666;
    position: absolute;
    top: 5px;
    left: 10px;
  } 
  .meeting-info-rm {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: bold;
  }
  .team-name-rm {
    font-size: 16px;
    color: #333;
  }
  .team-logo-rm {
    width: 25px;
    height: 25px;
  }
  .score-box-rm {
    background-color: #37043c;
    width: 50px;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
  }
  .meeting-venue-rm {
    font-size: 12px;
    color: #888;
    margin-right: 20px;
  }
  .arrow-icon-rm {
    font-size: 18px;
    color: #37043c;
    margin-right: 10px;
  }

  /* ÜÇÜNCÜ TABLO */
  .form-guide-container {
    background-color: #fdfdfd;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  /* Header */
  .form-guide-header-gc {
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    font-weight: bold;
    color: #37043c;
    margin-bottom: 10px;
  }
  /* Form Satırları */
  .form-guide-rows-gc {
    display: grid;
    grid-template-columns: 1fr 1fr; /* İki takım için iki sütun */
  }
  /* Tek Satır */
  .form-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
  }
  .form-table tr {
    display: flex;
  }
  .form-table tr:hover {
    background: linear-gradient(to right, #3ea7ff, #963cff);
    color: white;
  }
  .form-table td {
    padding: 8px 12px;
    font-size: 14px;
    background-color: transparent;
    border-bottom: #fff;
  }
  .column-left {
    text-align: left;
    font-weight: bold;
  }
  .column-center {
    text-align: center;
    color: #fff;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
  }
  .column-right {
    text-align: right;
    font-weight: bold;
  }
  /* Maç detayları */
  .match-details {
    text-align: left; /* Metni sola hizala */
  }
  /* Skor */
  .score-badge-gc {
    background-color: #37043c;
    color: #fff;
    font-weight: bold;
    width: 50px;
    padding: 5px 8px;
    border-radius: 5px;
  }
  /* Sonuç İkonları */
  .result-icon-gc {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-weight: bold;
    color: #fff;
    font-size: 12px;
  }
  .result-icon-gc.win {
    background-color: #4caf50; /* Yeşil */
  }
  .result-icon-gc.loss {
    background-color: #f44336; /* Kırmızı */
  }
  .result-icon-gc.draw {
    background-color: #9e9e9e; /* Gri */
  }



  .stat-box-head-to-head {
    padding: 15px;
    border: 1px solid #ddd;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  .season-stats-table-h {

    width: 100%;
    border-collapse: collapse;
    text-align: center;
    padding: 20px;
    font-size: 14px;
  }
  .season-stats-table-h th,
  .season-stats-table-h td {
    padding: 8px 12px;
    border-bottom: 1px solid #ddd;
  }
  .season-stats-table-h th {
    font-weight: bold;
    font-size: 16px;
    color: #37043c;
  }
  .team-header-h {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 5px;
  }
  .team-icon-h {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }
  .season-stats-table-h tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  .season-stats-table-h tr:hover {
    background-color: #f1f1f1;
  }
  







  /* Player Card Konteyner */
  .player-cards-container-head-to-head {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin-top: 20px;
  }
  
  .player-card-head-to-head {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 10px;
    width: 30%;
  }
  
  .player-card-head-to-head img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .player-card-head-to-head h4 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #37043c;
  }
  
  .player-card-head-to-head p {
    font-size: 14px;
    color: #666;
  }
  
  
  
  
  /* RESPONSIVE TASARIM */
  /* Mobil Görünüm için */

@media (max-width: 998px) {
    .player-image-t2{
        width:20px;
        height:20px;
    }    
    .layout-container {
      grid-template-columns: 1fr; /* Tek sütun düzeni */
      grid-template-rows: auto; /* Satırlar otomatik */
      gap: 15px; /* Boşluklar küçültüldü */
      padding: 10px; /* Kenar boşluklarını küçült */
    }
    /* Üstteki İki Tablolar Yan Yana */
    .top-tables {
        flex-direction: column; /* Mobilde alt alta diz */
        gap: 10px;
      }
    
      .table-top,
      .table-bottom {
        width: 100%; /* Mobilde tam genişlik */
      }
      .highlight-card {
        width: 130px;
      }
  
    /* Maç Kutusu Alt Tarafta */
    .large-box-match {
      grid-column: span 1; /* Tüm genişliği kaplar */
      width: 590px;
      height: auto; /* Dinamik yükseklik */
      padding: 15px; /* İçerik boşluğu */
    }
      .team-match {
        font-size: 8px;
        white-space: nowrap; /* Taşmayı engelle */
      }
      .team-logo-match {
        width: 30px;
        height: auto;
      }
      .score-box-match {
        font-size: 13px;
        min-width: 40px;
        height: 25px;
        text-wrap: nowrap;
      }
      .goals-list {
        font-size: 9px;
      }
  
    /* Sekme Alanı (Tam Genişlik) */
    .tabs-container {
      grid-column: span 1;
      width: 100%; /* Tam genişlik */
    }
  
    .tabs-buttons {
      flex-direction: row; /* Butonlar yatay */
      justify-content: center; /* Ortala */
      gap: 5px; /* Boşluklar küçült */
    }
  
    .tabs-buttons button {
      padding: 8px 12px; /* Buton boyutlarını küçült */
      font-size: 14px; /* Font boyutunu küçült */
    }
  
    .tabs-content {
      padding: 10px; /* Sekme içerik boşluğu küçült */
    }
    .tab-content-1 {
        grid-template-columns: 1fr; /* Tek sütun düzenine geç */
        gap: 15px; /* Elemanlar arası boşluk */
        padding: 10px; /* Daha küçük padding */
      }
    .head-to-head-container{
        width: 230px;
        overflow-x: auto;

    } .match-stats-container{
        width: 230px;
        overflow-x: auto;
      }
    .stat-box-head-to-head{
        overflow-x: auto;
    }
    .form-guide-header-gc{
        justify-content: space-between;
        gap: 260px;
    }
    .stats-container-match{
        flex-direction: column;
        overflow-x: auto;
        gap: 20px;
    }
    .bar-match {
        background-color: #eee;
        width: 60px;
        height: 25px;
        overflow: hidden;
      }
    .head-to-head{
        left: 0%;
    }
    .head-to-head-header h4 {
        display: inline-block;
        max-width: 2ch; /* Maksimum 3 karakter */
        overflow: hidden; /* Fazlalığı gizle */
        text-overflow: ellipsis; /* Fazlalığı "..." ile göster */
        white-space: nowrap; /* Metni tek satırda tut */
      }
    
    .match-comment-container {
        padding: 15px;
        font-size: 14px;
      }
  
    /* İçerik Alanları */
    .table,
    .large-box-match,
    .tabs-container {
      border-radius: 5px; /* Yuvarlak köşeler küçült */
      box-shadow: none; /* Gölgeyi kaldır */
    }

    .lineup-container {
        grid-template-columns: 1fr; /* Tek sütun düzeni */
        grid-template-rows: auto;
      }
    
      .team-players {
        width: 100%;
        order: 3; /* Oyuncu listelerini sahanın altına al */
      }
    
      .field-container {
        order: 2; /* Saha ortada kalsın */
        height: 100%; /* Daha küçük saha */
      }
    
      .position {
        width: 20px;
        height: 20px;
        font-size: 10px; /* Daha küçük noktalar */
      }
    
      .team-players.right-team {
        margin-top: 20px; /* Sağ takım oyuncuları alt alta */
      }
  }


  @media (max-width: 608px) {
    .large-box-match {
        grid-column: span 1; /* Tüm genişliği kaplar */
        width: 290px;
        height: auto; /* Dinamik yükseklik */
        padding: 15px; /* İçerik boşluğu */
      }
}
  
	
	
	
	
	
	
	
	
	
	
	
	
	
	/*           PLAYER               */
	
	
	
	
	
	
	
	
	
	
	
	
	/* Genel Stil */
body {
    margin: 0;
    font-family: 'Raleway', sans-serif;
    background-color: #f8f9fa;
}
.player-profile{
    display: flex;
    flex-direction: column;
    align-items:end;
}
.player-info {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.player-info h1 {
    font-size: 52px;
    padding-top: 50px;
    margin: 0;
    color: white;
    font-weight: lighter;
}
.player-info span {
    font-weight: bold;
}
.profile-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-color: #1b39ce; /* Ana koyu mavi renk */
    height: 250px;
    color: white;
    overflow: hidden;
    padding-right: 20px;
}
.profile-header::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        105deg,
        transparent 30%,
        rgba(30,63,227, 0.1) 30%,
        rgba(30,63,227, 200) 60%,
        transparent 60%
    );
    background-size: 200px 200px;
    opacity: 0.8; /* Hafif şeffaflık */
    z-index: 0; /* İçeriğin arkasında kalır */
}
.player-img {
    width: 200px;
    height: auto;
    padding-left: 200px;
}
.player-number {
    font-size: 200px;
    font-weight: bold;
    margin-top: -30px;
}
.profile-header * {
    position: relative; 
    z-index: 1;
}
.player-information{
    margin-top: -60px;
    width: 70%;
    z-index: 1;
    background-color: #f8f9fa;
}
/* stat ve overview butonlar */
.buttons {
    display: flex;
    justify-content:left;
    align-items: center;
    margin-top: -38px;
    gap: 5px;
    margin-left: 20px;
}
.button-results button, .button-team button, .button-fixture button , .button-stat button, .button-overview button{
    background-color: #c5c5c5;
    border: 1px solid #b4b4b4;
    border-top-left-radius:  10px;
    border-top-right-radius:  10px;
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: white;
}
.buttons button.active {
    background-color: white;
    color: #37043c;
    font-weight: bold;
}
/* stat ve overview İçerikleri */
.tab-content {
    margin-top: 20px;
    padding: 20px 0px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
/* stat için cssler */
.stats-summary {
    display: grid;
    grid-template-columns: repeat(4, 1fr); 
    gap: 20px;
    margin-top: 20px;
}
.stat-card {
    position: relative; /* Arka planda şimşek oluşturmak için */
    background-color: #fff; /* Kartın arka planı beyaz */
    padding: 10px;
    border-radius: 10px;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden; 
}
.stat-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 200%; /* Desenin genişliği */
    height: 200%; /* Desenin yüksekliği */
    background: repeating-linear-gradient(
        105deg,
        transparent 30%,
        rgba(86, 85, 85, 0.2) 30%,
        rgba(200, 200, 200, 0.2) 60%,
        transparent 60%
    );
    
        
    opacity: 0.3; /* Desenin yoğunluğunu ayarlar */
    z-index: -1; /* Kart içeriğinin altında tutar */
    transform: translate(-25%, -25%);
}
.stat-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}
.stat-card h3 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    color: #1b39ce;
}
.stat-card p {
    margin:10px;
    font-size: 50px;
    font-weight: bolder;
    color: #37043c;
}
.detailed-stats {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Stat barlar arasındaki boşluk */
}
.stat-item {
    text-align: left;
}
.stat-item h4 {
    margin-bottom: 8px; /* Başlık ile stat bar arasındaki boşluk */
    font-size: 16px;
    font-weight: bold;
    color: #333;
}
.stat-bar {
    background-color: #f0f0f0;
    border-radius: 10px;
    height: 10px;
    width: 100%;
    overflow: hidden;
}
.progress {
    height: 100%;
    background-color: #1b39ce;
    border-radius: 10px;
    transition: width 0.3s ease;
}
/* overview için cssler */
.match-list {
    margin-top: 20px;
    overflow-x: auto; /* Yatay kaydırmayı etkinleştir */
}
.match-list h3 {
    font-size: 18px;
    margin-bottom: 10px;
    margin-left: 10px;
    color: #333;
}
.match-table2 {
    width: 100%; /* Tablonun dışarı taşmasını engelle */
    border-collapse: collapse;
    text-align: left;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.match-table2 th,
.match-table2 td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    font-weight: bolder;
    font-size: 15px;
    text-align: center;
    color: #37043c;
}
.match-table2 th {
    background-color: #37043c;
    color: #fff;
    font-weight: bold;
    text-align: center;
}
.match-table2 tbody tr:hover {
    background: linear-gradient(to right, #3ea7ff, #963cff);
}
.team-logo2 {
    width: 20px; /* Logoların boyutunu ayarla */
    height: auto;
    margin-right: 5px;
}
/* oyuncu bilgileri için cssler */
.player-information-card {
    width: 20%;
    padding: 20px;
    margin: auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    position: absolute; /* Sabit bir yerde tut */
    top: 60%; /* Yukarıda konumlandır */
    left: 3%;
}

.player-title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.player-info-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.player-info-list li {
    margin-bottom: 18px;
    border-bottom: 0.5px solid #b4b4b4;
    font-size: 14px;
    color: #555;
}

.player-info-list h3 {
    color: #37043c;
}

.player-info-list li strong {
    color: #000;
}
@media (max-width: 1008px){
    .player-img {
        padding-left: 0px;
        width: 80%;
    }
    .player-info{
        width: 60%;
    }
    .player-info h1 {
        font-size: 25px;
        padding-top: 50px;
        margin: 0;
        color: white;
        font-weight: lighter;
    }
    .player-number{
        font-size: 100px;
    }
    .stats-summary {
        grid-template-columns: repeat(2, 1fr); /* 2 sütunlu grid */
    }
    .player-information{
        width: 100%;
    }
    .match-table2 td, .match-table2 h3{
        text-wrap: nowrap;
        font-size: 14px;
    }
    .match-table2{
        overflow-x: auto;
    }
    .player-information-card {
        width: 90%; /* Kart tam genişlikte olsun */
        position: relative; /* Normal akışa geri dön */
        margin: 20px auto; /* Ortada hizala */
        top: 0;
        left: 0;
    }
    .stat-card p {
        font-size: 35px;
    }
    .stat-card h3 {
        font-size: 11px;
        text-wrap: nowrap;
    }
}
	
	
	
	
	
	
	
	
	
	/*            PLAYER LİST DOUBLE               */
	
	
	
	
	
	
	
	
	/* Oyuncu Sayfası Genel Stili */
.player-container-pdl {
    max-width: 900px;
    margin: auto;
    text-align: center;
    padding: 20px;
  }
  
  /* Başlık */
  .player-container-pdl h1 {
    font-size: 24px;
    color: #430473;
    margin-bottom: 15px;
  }
  
  /* Kategori Seçimi */
  .select-container-pdl {
    margin-bottom: 20px;
  }
  
  .select-container-pdl select {
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  /* Tablo Stili */
  .player-table-pdl {
    width: 100%;
    overflow-x: auto;
  }
  
  .player-table-pdl table {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
  }
  
  .player-table-pdl caption {
    font-size: 20px;
    font-weight: bold;
    padding: 15px;
    background-color: #4facfe;
    color: white;
  }
  
  .player-table-pdl th,.player-table-pdl td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    text-align: left;
  }
  
  .player-table-pdl th {
    background-color: #f5f5f5;
    font-weight: bold;
  }
  
  /* Oyuncu ve Takım Linkleri */
  .player-link-pdl, .team-link-pdl {
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .player-img-pdl {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding-right: 5px;
  }
  
  
  
  /* Responsive Tasarım */
  @media (max-width: 768px) {
    .player-table-pdl {
      overflow-x: auto;
    }
  
    .player-table-pdl th, .player-table-pdl td {
      font-size: 14px;
    }
  
    .player-img-pdl {
      width: 20px;
      height: 20px;
    }
  }
  
	
	
	
	
	
	
	
	
	
	
	
	
	
	/*                   RESULTS                  */
	
	
	
	
	
	
	.tab-content{
    width: 100%;
}

.centered-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.text-cell {
    padding: 10px;
    width: 5%;
    text-align: right;
    white-space: nowrap;
}

.inline-block-container {
    display: inline-block;
}

.team-logo2 {
    width: 25px;
    margin-right: 10px;
    vertical-align: middle;
    padding: 10px;
}
.team-name2 {
    font-weight: bold;
    font-size: 17px;
    color: #37043c; /* Takım ismi rengi */
}

.link-style {
    white-space: nowrap;
    text-decoration: none;
    color: inherit;
}

.table-matches-style {
    width: 88%;
    border-collapse: collapse;
    background-color: white;
    border-radius: 1px;
    padding: 1px;
    display: block;
    overflow-x: auto;
}
.match-score {
    font-weight: bolder;
    font-size: 16px;
    color: white; /* Skor rengi */
    padding: 4px 8px;
    background-color: #37043c;
    border-radius: 5px; /* Skor kutucuğu */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}
.match-result {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px; /* Takım isimleri ve skor arasında boşluk */
}

.text-style-bold {
    font-family: 'Posterama 1927 SemiBold', sans-serif; 
    font-size: 12px;
    font-weight: bolder;
    text-align: center;
}

.logo-style2 {
    width: '25px'; 
    margin-right: '10px';
    vertical-align: 'middle';
}

.link-style {
    white-space: 'nowrap';
    text-decoration: 'none';
    font-weight: bold;
    color: 'inherit';
}

.date-header {
    text-align: left;
    padding: 10px;
    width: 25%;
    font-size: 20px;
    color: #37043c;
}

.score {
    background-color: #37043c;
    color: white;
    border-radius: 5px;
    padding: 5px;
    display: inline-block;
}

.row-hover {
    transition: background 0.3s ease;
}

.row-hover.hovered {
    background: linear-gradient(to right, #3ea7ff, #963cff);
    color: #f1f1f1;
}

	
	
	
	
	
	
	
	
	
	
	
	/*           RULES                */
	
	
	
	
	
	
	.rules-container {
    background-color: #f9f9f9;
    padding: 20px;
    max-width: 1000px;
    margin: 50px auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    font-family: 'Posterama 1927 SemiBold';
}

.rules-title {
    text-align: left;
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #37043c;
    font-family: 'Posterama 1927 SemiBold';
}

.rules-list {
    list-style-type: upper-alpha;
    font-size: 1rem;
    color: #37043c;
    line-height: 1.8;
    padding-left: 20px;
}

.rules-list li {
    margin-bottom: 15px;
}

.rules-list li::marker {
    color: #37043c;
    font-size: 1rem;
}

.rule-description {
    font-size: 1rem; /* Metin boyutu */
    color: #777; /* Renk */
    margin-top: 5px; /* Üstten boşluk */
    margin-bottom: 15px; /* Alttan boşluk */
}

.sub-rules-list {
    list-style: none;
    padding-left: 30px; /* Ana maddeden biraz daha içeride başlar */
}

.sub-rules-list li {
    position: relative;
    color: #777;
    padding-left: 20px;
    margin-bottom: 5px;
}

.sub-rules-list li::before {
    content: '✔';
    position: absolute;
    left: 0;
    color: #37043c;
    font-size: 1rem;
}
	
	
	
	
	
	
	
	
	
	
	
	
	/*            TEAM                  */
	
	
	
	
	
	
	
	.team-profile{
    display: flex;
    flex-direction: column;
    align-items:center;
}
/* burası header için cssler */
.team-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-color: #383333;
    background: repeating-linear-gradient(
        -45deg,
        #282828,
        #282828 20px,
        #333 20px,
        #333 40px
    );
    height: 450px;
    color: white;
    overflow: hidden;
    padding-right: 20px;
}
.sidebar2 img{
    width: 100px;
    margin-left: 65%;
    margin-top: -70px;
    height: auto;
    z-index: 1;
} 

.team-logo-img img {
    width: 200px;
    margin-left: 65%;
    margin-top: -70px;
    height: auto;
    z-index: 1;
}
.team-name-info{
    margin-bottom: 3%;
    margin-right: 50%;
}
.team-name-info p{
    font-size: 14px;
}
.team-name-info h1{
    font-size: 65px;
    font-family: 'Trebuchet MS' , sans-serif;
    text-align: left;
}
.team-information{
    margin-top: -60px;
    width: 70%;
    z-index: 1;
    background-color: #f8f9fa;
}

/* takım oyuncularının kart cssleri */
.team-player-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 10px;
    padding-left: 50px;
    padding-right: 50px;
    background-color: #f9f9f9;
}
.team-player-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: center;
}
.team-player-stats {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    background-color: #f9f9f9;
    padding: 10px;
}
.team-stat-item {
    padding: 5px;
    font-size: 12px;
    color: #555;
}
.team-stat-item h4 {
    margin: 0;
    font-size: 10px;
    color: #888;
}
.team-stat-item p {
    font-weight: bold;
    font-size: 16px;
    margin: 5px 0 0;
    color: #37043c;
}
.team-player-photo {
    width: 100%;
    background-color: #312d2e;
}
.team-player-photo img {
    width: 100%;
    height: auto;
}
.team-player-info {
    padding: 15px;
    text-align: center;
}
.team-player-info h2 {
    font-size: 18px;
    margin: 10px 0 5px;
    color: #37043c;
}
.team-player-info h2 span {
    font-weight: normal;
    display: block;
}
.team-player-info p {
    font-size: 14px;
    color: #555;
    margin: 5px 0;
}
.team-nationality {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
}
.team-nationality img {
    width: 20px;
    height: 15px;
    margin-right: 5px;
}
.team-nationality span {
    font-size: 12px;
    color: #555;
}
.team-view-profile {
    font-size: 14px;
    color: #37043c;
    text-decoration: none;
    font-weight: bold;
    margin-top: 10px;
    display: inline-block;
}
.team-view-profile:hover {
    color: #813bb6;
}
/* takım maçlarının tablo cssleri */
.match-result {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px; /* Takım isimleri ve skor arasında boşluk */
}
.team-name {
    font-weight: bold;
    font-size: 14px;
    color: #37043c; /* Takım ismi rengi */
}
.match-score {
    font-weight: bolder;
    font-size: 16px;
    color: white; /* Skor rengi */
    padding: 4px 8px;
    background-color: #37043c;
    border-radius: 5px; /* Skor kutucuğu */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}
.team-name:hover {
    color: #f8f9fa;
}
/* takım statları için cssler */
.stats-summary {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 20px;
    margin-top: 20px;
}
/* overview kısmı için cssler */
.overview-container {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 20px;
    padding: 20px;
}
.sidebar2 {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.sidebar-contain{
    gap: 30px;
}

.sidebar h3 {
    margin-bottom: 10px;
    color: #37043c;
}

.social-icons {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: flex-start;
    align-items: start;
    margin-top: 10px;
}

.social-link {
    display: flex;
    align-items: center;
    gap: 8px;
    text-decoration: none;
    color: #37043c;
    font-size: 16px;
    transition: color 0.3s ease;
}

.social-link i {
    font-size: 18px;
    color: #37043c;
}

.social-link:hover {
    color: #813bb6;
}

.social-link i:hover {
    color: #813bb6;
}


.main-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.membership {
    display: flex;
    gap: 20px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.membership-info h2 {
    color: #37043c;
}

.latest-news, .kits, .videos {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.news-cards, .kit-cards, .video-cards {
    display: flex;
    gap: 10px;
}

.news-card, .kit-card, .video-card {
    flex: 1;
    background-color: #f9f9f9;
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.news-card img, .kit-card img, .video-card img {
    width: 100%;
    border-bottom: 1px solid #ddd;
}

.news-card p, .video-card p {
    padding: 10px;
    font-size: 14px;
}

@media (max-width: 1200px){
    .team-name-info{
        margin-right: 30%;
    }
}
@media (max-width: 1008px){
    .team-logo-img img {
        margin-left: 35%;
        width: 100px;
    }
    .team-name-info{
        margin-bottom: 10%;
    }
    .team-name-info p{
        margin-left: 9%;
    }
    .team-name-info h1{
        font-size: 32px;
        text-align: left;
        margin-right: 300px;
        margin-left: 9%;
    }
    .team-player-card{
        width: 250px;
    }
    .stat-card h3 {
        font-size: 13px;
        text-wrap: wrap;
    }
}
	
	
	
