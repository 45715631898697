.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    text-align: center;
    position: relative;
}

.modal-title-addTeam {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.close-button-player {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 25px;
    background: none;
    border: none;
    cursor: pointer;
}

.team-selection {
    display: flex;
    justify-content: space-around;
    margin-bottom: 15px;
}

.team-select-button {
    padding: 10px;
    border: none;
    cursor: pointer;
    background: #008080;
    color: white;
    border-radius: 5px;
}

.team-select-button.selected {
    background: #013535;

}

.custom-input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
}

.team-list {
    max-height: 150px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
}

.team-item {
    padding: 8px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
}

.team-item:hover {
    background-color: #f0f0f0;
}

.team-item.selected {
    background: #008080;
    color: white;
}

.create-button {
    background: #008080;
    color: white;
    border: none;
    padding: 10px;
    width: 100%;
    cursor: pointer;
    border-radius: 5px;
}

.create-button:hover {
    background: #005959;
}
