/* Ana container */
.matches-container {
    padding: 40px;
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

/* Yeni Maç Ekle Butonu */
.add-button-mac {
    background-color: #008080;
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 28px;
    font-family: 'Trebuchet MS', sans-serif;
    transition: background-color 0.3s ease;
}

.add-button-mac:hover {
    background-color: #006666;
}
.update-button-mac{
    background-color: #008080;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
}
.update-button-mac:hover {
    background-color: #006666;
}


/* Maç Arama Alanı */
.search-bar-mac {
    margin-bottom: 20px;
    text-align: center;
}

.search-bar-mac input {
    width: 400px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #008080;
}

/* Sort Toggle */
.sort-toggle {
    display: flex;
    gap: 5px;
  }
  
  .sort-toggle button {
    padding: 4px 8px;
    font-family: 'Trebuchet MS', sans-serif;
    font-size: 10px;
    font-weight: bold;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #320037;
    cursor: pointer;
    color: white;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .sort-toggle button:hover {
    background-color: #008080;
    color: rgb(255, 255, 255);
  }
  
  .sort-toggle button.active {
    background-color: #008080;
    color: rgb(255, 255, 255);
  }

/* Tablo */
.matches-table {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
}

/* Tablo Başlığı */
.table-header-mac {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; /* 6 sütun */
    background-color: #320037;
    align-items: center;
    color: #f5f0ff;
    padding: 10px;
    border-radius: 10px;
    font-family: 'Trebuchet MS', sans-serif;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;
}

.match-item {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; /* 6 sütun */
    align-items: center;
    background-color: #fefefe;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-family: 'Trebuchet MS', sans-serif;
    font-size: 18px;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;
    text-align: center; /* Ortalamak için */
    white-space: nowrap; 
}


.match-item:hover {
    background-color: #e0d7f5;
}

/* Sayfalandırma */
.pagination-mac {
    margin-top: 20px;
    text-align: center;
}

.pagination-mac button {
    padding: 5px 10px;
    margin: 2px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    background-color: #008080;
}

.pagination-mac button.active {
    background-color: #006666;
    color: white;
}

.pagination-mac button:disabled {
    background-color: #008080;
    cursor: not-allowed;
}

.update-button-mac, .enter-stats-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.update-button-mac {
    background-color: #c74a41;
    color: white;
}

.update-button-mac:hover {
    background-color: #a53d1d;
}

.enter-stats-button {
    background-color: #4caf50;
    color: white;
}

.enter-stats-button:hover {
    background-color: #45a049;
}


/* Pop-up genel stil */
.filter-modal {
    position: fixed; /* Sayfada sabit kalsın */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Arka plan karartması */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Önde görünsün */
}

/* Pop-up içeriği */
.filter-modal-content {
    background-color: #fff;
    width: 400px; /* Pop-up genişliği */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    position: relative; /* Kapatma butonunu konumlandırmak için */
}

/* Başlık */
.filter-modal-content h3 {
    margin: 0 0 20px;
    font-size: 18px;
    color: #333;
    text-align: center;
}

/* Etiketler ve giriş alanları */
.filter-modal-content label {
    display: block;
    margin-bottom: 15px;
    font-size: 14px;
    color: #555;
}

.filter-modal-content input[type="text"],
.filter-modal-content input[type="checkbox"] {
    width: calc(100% - 10px); /* Kenarlıklar için yer bırak */
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

/* Checkbox label */
.filter-modal-content input[type="checkbox"] {
    width: auto; /* Checkbox için özel boyut */
    margin: 0 10px 0 0;
}

/* Pop-up altındaki butonlar */
.modal-actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.modal-actions button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Filtrele butonu */
.modal-actions button:first-child {
    background-color: #4CAF50; /* Yeşil */
    color: white;
}
.match-logo {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    object-fit: cover;
    border-radius: 50%;
}

.modal-actions button:first-child:hover {
    background-color: #45A049;
}

/* Kapat butonu */
.modal-actions button:last-child {
    background-color: #008080; /* Kırmızı */
    color: white;
}

.modal-actions button:last-child:hover {
    background-color: #006666;
}

/* Pop-up kapatma (X) butonu */
.filter-modal-content .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    color: #333;
    transition: color 0.3s ease;
}

.filter-modal-content .close-button:hover {
    color: #f44336;
}
.match-item-mobile{
    display: none;
}
.match-pair {
    display: flex;
    justify-content: space-between; /* Yan yana hizala */
    align-items: center; /* Dikey ortalama */
    padding: 5px 10px; /* İçerik boşlukları */
    background-color: #f9f9f9;
    border-radius: 5px;
    font-weight: bold;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Hafif gölge */
}

.match-pair span:first-child {
    text-align: left;
    flex: 1; /* İlk span daha geniş alan kaplar */
}

.match-pair span:last-child {
    text-align: right;
    flex: 1; /* İkinci span eşit genişlik alır */
}

.match-pair span::before {
    content: attr(data-label); /* Veri etiketini ekle */
    font-weight: normal;
    color: #666;
    margin-right: 10px;
}
@media (max-width: 766px) {
    .matches-container{
       width: 80%;
    }
    .table-header-mac {
       
        display: none; /* Tablo başlığını gizle */
    }
    .match-item-mobile{
        display:block;
    }
    .match-item{
        display: none;
    }

    .match-item-mobile {
        display: flex;
        flex-direction: column; /* Satırları dikey hizala */
        padding: 15px;
        gap: 5px; /* Hücreler arasına boşluk ekle */
    }

    .match-item-mobile span {
        display: flex;
        justify-content: space-between;
        padding: 5px;
        background-color: #f9f9f9;
        border-radius: 5px;
        font-weight: bold;
    }

    .match-item-mobile span::before {
        content: attr(data-label); /* Veri etiketini ekle */
        font-weight: normal;
        color: #666;
        margin-right: 10px;
    }

        /* Maç Arama Alanı */
    .search-bar-mac {
        margin-bottom: 20px;
        text-align: center;
    }

    .search-bar-mac input {
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #008080;
    }
}
