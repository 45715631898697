/* General Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #ffffff;
    border-radius: 8px;
    width: 500px;
    max-width: 90%;
    max-height: 80%;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.modal-title {
    font-size: 1.5em;
    font-weight: bold;
    margin: 0;
}

.close-button {
    background: none;
    border: none;
    font-size: 1.5em;
    font-weight: bold;
    color: #333;
    cursor: pointer;
}

.close-button:hover {
    color: #ff5555;
}

/* Search Bar */
.search-bar {
    margin-bottom: 15px;
}

.search-bar input {
    width: 100%;
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
}

.search-bar input:focus {
    border-color: #4a90e2;
}

/* Player List */
.player-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.player-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #f0f0f0;
}

.player-list-item:last-child {
    border-bottom: none;
}

.player-list-item:hover {
    background-color: #f9f9f9;
}

.player-name {
    font-size: 1em;
    font-weight: 500;
}

/* Remove Button */
.remove-button {
    background-color: #ff5555;
    color: #ffffff;
    padding: 8px 12px;
    font-size: 0.9em;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.remove-button:hover {
    background-color: #cc4444;
}

/* Error Message */
.error-message {
    color: #ff5555;
    font-size: 0.9em;
    margin-top: 10px;
    text-align: center;
}
.r-team-list {
    margin-top: 10px;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
}

.r-team-item {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 5px;
    background-color: #f9f9f9;
    border: none;
    text-align: left;
    font-size: 1em;
    cursor: pointer;
    border-radius: 5px;
}

.r-team-item:hover {
    background-color: #e6e6e6;
}

.r-team-item.selected {
    background-color: #4a90e2;
    color: #fff;
}
/* Scrollable List Styling */
.scrollable {
    overflow-y: auto;
    max-height: 300px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.scrollable::-webkit-scrollbar {
    width: 6px;
}

.scrollable::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 3px;
}

.scrollable::-webkit-scrollbar-thumb:hover {
    background-color: #888;
}
