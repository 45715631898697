/* Menü Bar */
.editor-menu-bar {
  background-color: #320037;
  color: white;
  width: 250px;
  height: 100vh;
  padding-top: 9px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: -260px; /* Menü başlangıçta gizlenir */
  top: 0;
  transition: left 0.3s ease-in-out;
  z-index: 999;
  overflow-y: scroll;

  font-family: "Trebuchet MS", sans-serif;
}

.editor-menu-bar.open {
  left: 0; /* Menü açıldığında sola hizalanır */
}

.logo-container2 {
  margin-top: 30px;
}

.logo2 {
  width: 200px;
  height: auto;
}

/* Menü Öğeleri */
.menu-item {
  margin: 10px 0;
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
}

.menu-item.active {
  color: #320037;
}

/* Logout Butonu */
.logout-button {
  margin-top: 0;
  padding: 10px 20px;
  background-color: #db0f0f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Trebuchet MS", sans-serif;
  font-weight: bold;
  font-size: 15px;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #91300a;
}

.user-city {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #320037;
  padding: 0px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 15px;
}

/* Menü Toggle Butonu */
.menu-toggle {
  position: fixed;
  top: 10px;
  left: 10px;
  background-color: #320037;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  z-index: 1000;
}

.menu-icon {
  font-size: 30px;
  font-weight: bold;
}

/* Kapatma Butonu */
.close-menu {
  align-self: flex-end;
  margin: 0 10px 20px 0;
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.close-menu:hover {
  color: #320037;
}

/* Mobil Uyumluluk */
@media (max-width: 768px) {
  .editor-menu-bar {
    width: 200px;
  }

  .menu-item {
    font-size: 14px;
  }

  .logout-button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .menu-toggle {
    padding: 8px;
  }
}
