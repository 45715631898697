.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    max-height: 70vh; /* Yüksekliği azaltıldı */
    overflow-y: auto; /* Dikey kaydırma eklendi */
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    width: 90%;
    max-width: 600px;
    position: relative;
}

.modal-content .short-input {
    width: 250px !important; /* Genişliği zorla kısaltmak için !important ekledik */

    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #ffffff; /* Arka planı beyaz */
    font-family: 'Trebuchet MS', sans-serif;
    font-weight: bold;
    font-size: 14px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    position: relative;

}

.modal-content .short-input:focus {
    outline: none;
    border-color: #008080; /* Odaklandığında kenar rengi değişir */
}

.modal-title {
    background-color: #008080;
    color: #ffffff;
    font-size: 24px; /* Yazı boyutunu koruyoruz */
    text-align: center;
    margin-bottom: 15px; /* Alt boşluk */
    font-family: 'Trebuchet MS', sans-serif;
    font-weight: bold;
    padding: 20px; /* Daha fazla iç boşluk eklenir */
    border-radius: 10px; /* Kenarların daha oval olması için artırıldı */
    width: 90%; /* Genişliği artırılır */
    margin: 0 auto 20px; /* Ortalar ve alt boşluk ekler */
}

.close-button-updateTeam {
    position: absolute; /* Sabit pozisyon */
    top: 2px; /* Üstten uzaklık */
    right: 2px; /* Sağdan uzaklık */
    width: 30px; /* Buton genişliği */
    height: 30px; /* Buton yüksekliği */
    background-color: transparent; /* Şeffaf arka plan */
    color: #000; /* Siyah yazı rengi */
    border: none; /* Çerçeve kaldırılır */
    font-size: 25px; /* Yazı boyutu */
    font-weight: bold; /* Yazı kalınlığı */
    cursor: pointer; /* İmleç değişimi */
    border-radius: 50%; /* Daire şekli */
    display: flex; /* Flexbox kullanılarak içerik ortalanır */
    justify-content: center; /* Yatayda ortalama */
    align-items: center; /* Dikeyde ortalama */
    transition: all 0.3s ease; /* Geçiş animasyonu */
}
.close-button-updateTeam:hover {
    background-color: #f5f5f5; /* Hover durumunda açık gri arka plan */
    color: #008080; /* Hover durumunda yazı rengi */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); /* Hafif bir gölge */
}

.modal-scrollable {
    max-height: 80vh;
    overflow-y: auto;
}

.update-field {
    margin-bottom: 20px;
}

.update-field label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.update-field input,
.update-field select {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.update-field button {
    margin-top: 10px;
    background-color: #008080;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.update-team-delete-button {
    background-color: rgb(255, 0, 0);
    color: white;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 0px;
    width: 100%;
}
.update-field-switch{
    margin-bottom: 20px;

}
.switch-button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    color: white;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.2s ease; /* Geçiş animasyonu */
}

.switch-active {
    background-color: green;
}

.switch-active:hover {
    background-color: darkgreen; /* Hover durumunda daha koyu bir yeşil */
    transform: scale(1.05); /* Biraz büyütme efekti */
}

.switch-inactive {
    background-color: gray;
}

.switch-inactive:hover {
    background-color: darkgray; /* Hover durumunda daha koyu bir gri */
    transform: scale(1.05); /* Biraz büyütme efekti */
}

