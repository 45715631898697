/* Ana container */
.leagues-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; /* İkiye böl */
    gap: 150px;
    padding: 50px;
    overflow-x: auto; /* Yana kaydırmayı etkinleştir */
}

/* Mevcut Ligler Bölümü */
.existing-leagues {
    padding: 5px;
    width: 600px;
    max-height: 400px; /* Maksimum yükseklik */
    overflow-x: auto; /* Yana kaydırmayı etkinleştir */
    
}
.league-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: #f9f9f9;
}
.delete-button {
    background-color: #ff4d4f;
    color: white;
    border: none;
    width: 35px ;
    height: 35px;
    margin-bottom: px;
    border-radius: 4px;
    padding: 0px 5px;
    cursor: pointer;
    font-size: 14px;
}

.delete-button:hover {
    background-color: #ff7875;
}
/* Ligler Tablosu */
.leagues-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 400px;
    overflow-y: auto;
}

/* Başlık */
.section-title {
    background-color: #320037;
    color: #f5f0ff;
    padding: 10px;
    text-align: center;
    border-radius: 10px;
    font-family: 'Trebuchet MS', sans-serif;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

/* Lig Elemanları */
.league-item {
    display: flex;
    align-items: center;
    height: 30px;
    justify-content: space-between;
    background-color: #fefefe;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-family: 'Trebuchet MS', sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: #333;
}

/* Yeni Lig Ekle Bölümü */
.add-league-section {
    background-color: #fefefe;
    padding: 20px;
    width: 400px;
    height: 300px;
    border-radius: 10px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);

}

/* Yeni Lig Ekle Başlığı */
.add-league-title {
    font-family: 'Trebuchet MS', sans-serif;
    font-size: 25px;
    font-weight: bold;
    color: #320037;
    margin-bottom: 10px;
}

/* Input Alanı */
.league-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
}

/* Oluştur Butonu */
.add-button {
    width: 50%;
    margin-left: 105px;
    margin-top: 20px;
    padding: 10px;
    background-color: #320037;
    color: #fff;
    font-family: 'Trebuchet MS', sans-serif;
    font-size: 24px;
    border: none;
    font-weight: bold;

    border-radius: 5px;
    cursor: pointer;
}

.add-button:hover {
    background-color: #250027;
}

/* Hata Mesajı */
.error-message {
    color: red;
    font-size: 14px;
    margin-top: 10px;
}

.success-message {
    color: green;
    font-size: 14px;
    margin-top: 10px;
}

/* Mobil Görünüm */
@media (max-width: 768px) {
    .leagues-container {
        grid-template-columns: 1fr; /* Tek sütuna geç */
    }


    .add-league-section,.existing-leagues {
        margin-top: 0px; /* Alt tarafa yerleştir */
        width: 100%;
    }
}

