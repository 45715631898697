.body-p {
    display: flex;
    flex-direction: column;
}
/* player headerı */
.player-img-p {
    width: 345px;
    height: auto;
    z-index: 1000;
}
.header-banner-p {
    position: relative;
    height: 350px; /* Yükseklik */
    background: linear-gradient(45deg, #152f46, #6356b9); /* Mavi ve mor geçiş */
    display: flex;
    justify-content:space-between; /* Yatayda ortala */
    align-items: center; /* Dikeyde ortala */
    color: white; /* Yazı rengi */
    text-align: center;
    overflow: hidden;
    margin-bottom: 30px;
}
.banner-title-p {
    font-size: 45px; 
    font-weight: bold; 
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); 
    z-index: 2; 
    color: white;
}
.id-p {
    font-size: 200px; 
    font-weight: bold; 
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); 
    z-index: 2; 
    position: relative;
    color: white;
    padding: 2%;
}
.zigzag-p {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    overflow: hidden;
}
.zigzag-p svg {
    display: block;
}

/* main contentin gridi */
.player-profile-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
  padding: 20px;
  margin: auto;
}

/* Kişisel Bilgiler Kartı */
.player-card-p {
    background: linear-gradient(135deg, #152f46, #6356b9);
    color: white;
    border-radius: 15px;
    padding: 20px;
    width: 320px;
    height: 250px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
}
.player-team-p {
    font-size: 14px;
    font-weight: bold;
    color: #f0f0f0;
}
.player-details-p {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.detail-row-p {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 5px 0;
}
.detail-label-p {
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    color: #f0f0f0;
} 
.detail-value-p {
    font-size: 16px;
    text-align: right;
    font-weight: bold;
    color: #ffeb3b;
}

/* Filtre Kısmı İçin */
.filter-card-p {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    background: white;
    color: black;
    height: fit-content;
    padding: 15px;
    border-radius: 10px;
    text-align: center;
    font-size: 14px;
    display: grid;
    position:static;
  grid-template-columns: repeat(2, 1fr);
}
.filter-card-p h3 {
    font-size: 16px;
    margin-bottom: 10px;
}
.filter-group-p h4 {
    font-size: 16px;
    margin-bottom: 8px;
    text-align: left;
}
.filter-group-p label {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 5px 0;
}
.filter-group-p input[type="checkbox"] {
    width: 16px;
    height: 16px;
    cursor: pointer;
}
.team-logo-p {
    width: 30px;
    height: auto;
    padding: 0%;
}

  
/* Detaylı */
.detailed-card-p {
    grid-row: 1 / 3;
    grid-column: 2 / 3;
    background: #ecebeb;
    padding: 20px;
    border-radius: 10px;
    text-align: left;
}
.detailed-card-p h3 {
    font-size: 18px;
    margin-bottom: 15px;
}

/* İstatistikler */
.stats-summary-p {
    display: grid;
    grid-template-columns: repeat(4, 1fr); 
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: center;
}
.stat-card-p {
    position: relative; /* Arka planda şimşek oluşturmak için */
    background-color: #fff; /* Kartın arka planı beyaz */
    padding: 10px;
    border-radius: 10px;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden; 
}
.stat-card-p::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 200%; /* Desenin genişliği */
    height: 200%; /* Desenin yüksekliği */
    background: repeating-linear-gradient(
        105deg,
        transparent 30%,
        rgba(86, 85, 85, 0.2) 30%,
        rgba(200, 200, 200, 0.2) 60%,
        transparent 60%
    );
    
        
    opacity: 0.3; /* Desenin yoğunluğunu ayarlar */
    z-index: -1; /* Kart içeriğinin altında tutar */
    transform: translate(-25%, -25%);
}
.stat-card-p:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}
.stat-card-p h3 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    color: #152f46;
}
.stat-card-p p {
    margin:10px;
    font-size: 50px;
    font-weight: bolder;
    color: #152f46;
}
.detailed-stats-p {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    gap: 20px; /* Stat barlar arasındaki boşluk */
}
.stat-item-p {
    text-align: left;
}
.stat-item-p h4 {
    margin-bottom: 8px; /* Başlık ile stat bar arasındaki boşluk */
    font-size: 16px;
    font-weight: bold;
    color: #333;
}
.stat-bar-p {
    background-color: #f0f0f0;
    border-radius: 10px;
    height: 10px;
    width: 100%;
    overflow: hidden;
}
.progress-p {
    height: 100%;
    background-color: #152f46;
    border-radius: 10px;
    transition: width 0.3s ease;
}

/* Yapılan Maçlar */
.match-list-p {
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.match-list-p h3 {
  text-align: left;
  color: #333;
  margin-bottom: 15px;
  font-size: 22px;
}
.match-list-p table {
  border-collapse: collapse;
  background: white;
  border-radius: 8px;
  overflow: hidden;
}
.match-list-p th, .match-list-p td {
  width: 10%;
  padding: 12px;
  text-align: center;
  border: 0px solid #ddd;
}
.match-list-p th {
  background: #152f46;
  color: white;
  font-weight: bold;
}
.match-list-p tr:nth-child(even) {
  background: #f2f2f2;
}
.match-list-p tr:hover {
  background: #ddd;
}
.match-list-p td {
  color: #444;
  font-size: 16px;
}
.match-list-p td:nth-child(1) { /* Tarih sütunu */
  font-weight: bold;
}
.match-list-p td:nth-child(5) { /* Goller & Dakikalar */
  color: #e63946;
  font-weight: bold;
}
.match-list-p td:nth-child(7) { /* Kazanılan Puan */
  font-weight: bolder;
  color: #28a745;
}
.match-list-div-p{
  overflow-x: auto;
}
.opponent-team-p {
  display: flex;
  align-items: center;
  gap: 10px;
  text-wrap:nowrap;
}
.opponent-team-logo-p {
  width: 25px;
  height: 25px;
  object-fit: contain;
}

/* İstatistik Grafikleri */
.chart-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  margin-bottom: 50px;
}
.chart-box {
  width: 45%;
  height: 300px;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.chart-box h3 {
  padding: 0%;
}
  
@media (max-width: 1024px) {
    .header-banner-p{
        flex-direction: column;
        height: 550px;
    }
    .id-p {
        display: none;
    }
    .banner-title-p {
        margin-bottom: 60px;
    }
    .zigzag-p {
        display: none;
    }
    .player-card-p {
        width: 70%;
      }
      .detail-label,
    .detail-value {
      font-size: 12px;
    }
    .player-profile-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        padding: 0%;
        width: 100%;
      }
    
      .filter-card-p,
      .detailed-card-p {
        width: 80%; /* Kartlar tam genişlikte olacak */
        text-align: center;
      }

      .stats-summary-p {
        grid-template-columns: repeat(2, 1fr); /* 2 sütunlu grid */
    }
  .player-info-p{
      width: 60%;
  }
  .player-info-p h1 {
      font-size: 25px;
      padding-top: 50px;
      margin: 0;
      color: white;
      font-weight: lighter;
  }
  .player-number-p{
      font-size: 100px;
  }
  .stats-summary-p {
      grid-template-columns: repeat(2, 1fr); 
      width: 80%;
  }
  .stats-content-p{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .detailed-card-p {
    width: 80%;
    overflow-x: auto;
  }
  .chart-container {
    flex-direction: column; /* Grafiklerin alt alta sıralanmasını sağla */
    align-items: stretch; /* Grafiklerin tamamı genişlik olarak yayılacak */
    justify-content: center;
    align-items: center;
  }

  .chart-box {
    width: 80%; /* Her iki grafiğin genişliği de %100 olacak */
    height: 250px;
    margin-bottom: 40px; /* Aralarına boşluk ekleyin */
    justify-content: center;
  }
 
  }