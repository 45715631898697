.add-match-container {
    max-width: 650px;
    margin:  auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
.add-match-title {
  background-color: #008080;
  color: #ffffff;
  font-size: 24px;
  text-align: center;
  margin-bottom: 15px;
  margin-top: 15px;
  font-family: 'Trebuchet MS', sans-serif;
  font-weight: bold;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  margin: 0 auto 20px;
}
  
.add-match-form-group {
  margin-bottom: 15px;
}

.add-match-label {
  color: black;
  font-family: 'Trebuchet MS', sans-serif;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  width: 95%;
  margin-top: 5px;
  transition: all 0.3s ease-in-out;
}

.add-match-input, .add-match-select {
  background-color: white;
  color: black;
  font-family: 'Trebuchet MS', sans-serif;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 95%;
  margin-bottom: 5px;
  margin-top: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

.add-match-team-box {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.add-match-team-section {
  border: 2px solid #006666;
  border-radius: 10px;
  padding: 15px;
  width: 45%;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.add-match-player-search-input {
  width: 100%;
  padding: 5px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.add-match-toggle-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
  gap: 5px;
  background-color: #008080;
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Trebuchet MS', sans-serif;
  font-weight: bold;
  color: #ffffff;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.add-match-toggle-button:hover {
  background-color: #006666;
}

.add-match-toggle-button span {
  display: inline-block;
  transition: transform 0.3s ease;
}

.add-match-toggle-button .arrow {
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #ffffff; /* Üçgen şekli */
  transform: rotate(-90deg);
}

.add-match-toggle-button.open .arrow {
  transform: rotate(0deg); /* Aşağıya döndür */
}

.add-match-add-player-button {
  background-color: #008080;
  font-family: 'Trebuchet MS', sans-serif;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 15px;
  padding: 10px 15px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.add-match-add-player-button:hover {
  background-color: #006666;
}


.add-match-player-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.add-match-player-list-item {
  display: flex;
  align-items: center;
  padding: 5px 0;
  gap: 10px;
}

.add-match-player-list-item input[type="checkbox"] {
  margin: 0;
  width: 18px;
  height: 16px;
}

.add-match-scrollable {
  max-height: 150px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 5px;
  margin-top: 5px;
}

.add-match-stat-button {
  width: 100%;
  padding: 10px;
  background-color: #320037;
  color: white;
  border: none;
  border-radius: 10px;
  font-family: 'Trebuchet MS', sans-serif;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}

.add-match-stat-button:hover {
  background-color: #4a005f;
}

/* İstatistikler Konteyneri */
.add-match-statistics-container {
  margin-top: 20px;
}

/* Oyuncu İstatistik Tablosu */
.add-match-player-statistics-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

/* Tablo Başlıkları ve Hücreleri */
.add-match-player-statistics-table th,
.add-match-player-statistics-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

/* Tablo Başlıkları */
.add-match-player-statistics-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

/* Tablo Hücreleri İçindeki Girdi ve Seçimler */
.add-match-player-statistics-table td input,
.add-match-player-statistics-table td select {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
}

.add-match-team-search-input {
  margin-top: 10px;
  padding: 5px;
  width: 100%;
  box-sizing: border-box;
}

.add-match-team-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 10px;
}

.add-match-team-list-item {
  padding: 5px;
  cursor: pointer;
  background-color: #ffffff;
  border: 1px solid #ccc;
  margin-bottom: 5px;
  font-family: 'Trebuchet MS', sans-serif;
  font-size: 15px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.add-match-team-list-item:hover {
  background-color: #e6e6e6;
}

.add-match-team-list-item.selected {
  background-color: #ffffff;
}

.add-match-selected-team-info {
  font-weight: bold;
  margin-top: 10px;
}

.add-match-checkbox-container {
  display: flex;
  align-items: center;
  font-family: 'Trebuchet MS', sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-top: 10px;
  gap: 10px;
}

.add-match-checkbox-container input[type="checkbox"] {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  cursor: pointer;
}

.add-match-checkbox-container:hover {
  color: #006666;
}

.add-match-checkbox-container input[type="checkbox"]:hover {
  border-color: #006666;
}

.add-match-scrollable {
  max-height: 150px; /* Maksimum yükseklik */
  overflow-y: auto; /* Dikey kaydırma */
  border: 1px solid #ccc; /* Kenarlık */
  padding: 5px; /* İçerik boşluğu */
  margin-top: 5px; /* Üst boşluk */
  background-color: #f9f9f9; /* Arka plan rengi */
}

/* Mobil Görünüm */
@media (max-width: 768px) {
  .add-match-team-box {
      flex-direction: column; /* Dikey hizalama */
      align-items: center;
      flex-wrap: wrap; 
  }

  .add-match-team-section {
      width: 100%; /* Mobilde tam genişlik */
      margin-bottom: 20px; /* Bölümler arasında boşluk */
  }

  .add-match-player-list {
      max-height: 200px; /* Uzun liste durumunda kaydırma */
      overflow-y: auto;
  }
}
