.table-header-player {
    display: grid; /* Flex yerine grid kullanıldı */
    grid-template-columns: 1fr 1fr 1fr ; /* Kolon genişlikleri */
    background-color: #320037;
    color: #f5f0ff;
    padding: 10px;
    border-radius: 10px;
    font-family: 'Trebuchet MS', sans-serif;
    font-weight: bold;
    font-size: 20px;
}
.player-logo {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    object-fit: cover;
    border-radius: 50%;
}


/* Tablo Satırları */
.team-item-player {
    display: grid; /* Flex yerine grid kullanıldı */
    grid-template-columns: 1fr 1fr 1fr ; /* Kolon genişlikleri */
    align-items: center; /* Dikey hizalama */
    background-color: #fefefe;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-family: 'Trebuchet MS', sans-serif;
    font-size: 18px;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;
    overflow-x: auto;
}

.team-item-player:hover {
    background-color: #e0d7f5;
}

/* Tablo Hücreleri (Başlık ve içerik hizalaması) */
.table-header-player span, .team-item span {
    text-align: left;
    padding: 5px 10px; /* Hücre içi boşluk */
}

/* Tablo satırları ve başlıkları hizalı */
.team-item-player span {
    flex: 1;
    text-align: left;
}
.empty-space-player {
    display: inline-block;
    width: 10px; /* Genişliği buradan ayarlayın */
}
