/* Ana container */
.teams-container {
    padding: 40px;
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-top: 20px;
}

.pagination button, .pagination .ellipsis {
    padding: 5px 10px;
    margin: 0 2px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    background-color: #008080;
    color: white;
}
.team-logo {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    object-fit: cover;
    border-radius: 50%;
}


.pagination button.active {
    background-color: #006666;
    font-weight: bold;
}

.pagination button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.pagination .ellipsis {
    background: none;
    color: #999;
    cursor: default;
    font-size: 14px;
}


.search-bar {
    margin-bottom: 20px;
    text-align: center;
}

.search-bar input {
    width: 400px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #008080;
}
/* Yeni Takım Ekle Butonu */
.add-buttonn {
    background-color: #008080;
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 28px;
    font-family: 'Trebuchet MS', sans-serif;

    transition: background-color 0.3s ease;
}

.add-buttonn:hover {
    background-color: #006666;
}

.teams-table {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    overflow-x: auto;
}

/* Tablo Başlığı */
.table-header-team {
    display: grid; /* Flex yerine grid kullanıldı */
    grid-template-columns: 1fr 1fr 1fr 1fr; /* Kolon genişlikleri */
    background-color: #320037;
    color: #f5f0ff;
    padding: 10px;
    border-radius: 10px;
    font-family: 'Trebuchet MS', sans-serif;
    font-weight: bold;
    font-size: 20px;
      overflow-x: auto;
}

/* Tablo Satırları */
.team-item {
    display: grid; /* Flex yerine grid kullanıldı */
    grid-template-columns: 1fr 1fr 1fr 1fr; /* Kolon genişlikleri */
    align-items: center; /* Dikey hizalama */
    background-color: #fefefe;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-family: 'Trebuchet MS', sans-serif;
    font-size: 18px;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;
      overflow-x: auto;
}

.team-item:hover {
    background-color: #e0d7f5;
}

/* Tablo Hücreleri (Başlık ve içerik hizalaması) */
.table-header span, .team-item span {
    text-align: left;
    padding: 5px 10px; /* Hücre içi boşluk */
}

/* Tablo satırları ve başlıkları hizalı */
.team-item span {
    flex: 1;
    text-align: left;
}
.empty-space {
    display: inline-block;
    width: 10px; /* Genişliği buradan ayarlayın */
}

/* Güncelle Butonu */
.update-button {
    background-color: #008080;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.update-button:hover {
    background-color: #006666;
}
